import React, { Component } from "react";
import {
    COMPANY_NAME_INC, 
    COMPANY_CITY, 
    COMPANY_STATE, 
    COMPANY_STREET,
    COMPANY_ZIP
} from 'utils/constants'
import ContactUs from 'pages/contact_us';
import logo from 'assets/reputation/logo_green.svg';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
            displayWaitList: false,
        };

        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
    }

    setContactUsDisplay(value) {
        this.setState({displayContactUs: value });
    }
    
    render() {
        return(
            <div class="footer">
                <ContactUs 
                    displayStatus={this.state.displayContactUs} 
                    changeDisplayStatus={this.setContactUsDisplay}
                />
                <div class="footer container pt-3 pb-3">
                <div class="row gy-3">
                    <div class="col-12 col-md-4">
                        <div class="col"><img src={logo} class="brandLogo"/></div>
                        <div class="address text-white  mt-4">
                            <div><strong><small>{COMPANY_NAME_INC}</small></strong></div>
                            <div><small>{COMPANY_STREET}</small></div>
                            <div><small>{COMPANY_CITY} {COMPANY_STATE} {COMPANY_ZIP}</small></div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 text-white">
                        <div class="text-light-green fw-bold"><small>About the Company</small></div>
                        <div class="link">
                            <a class="text-white text-decoration-none"  role="button" href='/privacy_policy' ><small>Privacy policy</small>   </a> 
                        </div>
                        <div class="link">
                            <a class="text-white text-decoration-none"  role="button" href='/tos'><small>Terms of service</small> </a> 
                        </div>
                        <div class="link">
                            <a class="text-white text-decoration-none"  role="button" href='/about_us' ><small>About us</small></a> 
                        </div>
                        <div class="link">
                            <a class="text-white text-decoration-none"  role="button" onClick={ () => this.setContactUsDisplay(true) } >
                            <small>Contact us</small>
                            </a> 
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="text-light-green fw-bold"><small>Quick Links</small></div>
                           <div class="link">
                                <a class="text-white text-decoration-none" role="button" href='/property_inspector'><small>Property inspector</small></a> 
                               {/* <Link to={{ pathname: "/property_inspector"}} >
                                   Property Inspector
                               </Link>  */}
                           </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col fs-light text-white"><small>Copyright &copy; 2024 Renolition Inc. All rights reserved.</small></div>
                </div>
                </div>
                        
            </div>
        );
    }
}

export default Footer;