import { EntityRole } from "./constants";

export const isValidPassword = (password) => {
    // (?=.*\d)          // should contain at least one digit
    // (?=.*[a-z])       // should contain at least one lower case
    // (?=.*[A-Z])       // should contain at least one upper case
    // [a-zA-Z0-9]{8,}   // should contain at least 8 from the mentioned characters
    // (?=.*[!@#$%^&*_])  // at least one special character

    let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_])[0-9a-zA-Z!@#$%^&*_]{8,}$/
    return password && reg.test(password)
}

export const isValidEmail = (email) => {
    let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email && reg.test(email)
}

export const isValidPhoneNumber = (phoneNumber) => {
    return /^\d+$/.test(phoneNumber) && phoneNumber.length === 10;
}

export const isVendor = (role) => {
    return role === EntityRole.CONTRACTOR || role === EntityRole.ARCHITECT || role === EntityRole.ENGINEER || role === EntityRole.OTHER_BUSINESS;
}

export const isConstructionVendor = (role) => {
    return role === EntityRole.CONTRACTOR || role === EntityRole.ARCHITECT || role === EntityRole.ENGINEER;
}

export const answerFromBool = (value) => {
    if (value === true) {
        return "Yes";
    }
    else if (value === false) {
        return "No";
    }
    else return null;
}

export const truncateText = (text, max_length, with_ellipses=false) => {
    
    if (text.length > max_length){
        let sub_string = text.substring(0, max_length);
        
        if (with_ellipses){
            sub_string = sub_string + '...';
        }

        return sub_string;
    }

    return text;
}