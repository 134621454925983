
export const OakPlanningRecordType = {
    APPEAL: 'Appeal',
    CREEK_PERMIT: 'Creek Permit',
    DEVELOPMENT_PERMIT: 'Development Permit',
    ENVIRONMENTAL_REVIEW: 'Environmental Review',
    LANDMARK_DESIGNATION: 'Landmark Designation',
    LETTER_OF_DETERMINATION: 'Letter of Determination',
    MILLS_ACT: 'Mills Act',
    NEPA_EXCLUSION_ONLY: 'NEPA Exclusion Only',
    NEPA_STUDY: 'NEPA Study',
    PLANNED_UNIT_DEVELOPMENT_FINAL: 'Planned Unit Developmen Final',
    PRE_APPLICATION: 'Pre-Application',
    REVISION: 'Revision',
    SMALL_PROJECT_DR: 'Small Project DR',
    ZONING_CLEARANCE: 'Zoning Clearance',
    ZONING_WORKSHEET: 'Zoning Worksheet',
}

export const OakPlanningFileType = {
    'PLN-Submittal-Maps': 'PLN-Submittal-Maps',
    'PLN-Email Notification': 'PLN-Email Notification',
    'CE-Inspection General': 'CE-Inspection General',
    'BLD-APPLICATION OR ISSUANCE REPORT': 'BLD-APPLICATION OR ISSUANCE REPORT',
    'ACA-ENERGY CALCULATIONS': 'ACA-ENERGY CALCULATIONS',
    'BLD-Other': 'BLD-Other',
    'PLN-SUBMITTAL-APPLICATION': 'PLN-SUBMITTAL-APPLICATION',
    'Email Notification': 'Email Notification',
    'PLN-Plans': 'PLN-Plans',
    'PLN-GENERAL': 'PLN-GENERAL',
    'Application or Issuance Report': 'Application or Issuance Report',
    'PLN-Submittal-Supplemental Forms': 'PLN-Submittal-Supplemental Forms',
    'FINANCE-GENERAL': 'FINANCE-GENERAL',
    'PLN-PLANS': 'PLN-PLANS',
    'PLN-SUBMITTAL-PHOTOGRAPHS': 'PLN-SUBMITTAL-PHOTOGRAPHS',
    'FINANCE-General': 'FINANCE-General',
    'CE-NOTIFICATIONS GENERAL': 'CE-NOTIFICATIONS GENERAL',
    'PLN-SUBMITTAL-MAPS': 'PLN-SUBMITTAL-MAPS',
    'Maps': 'Maps',
    'Staff Report': 'Staff Report',
    'PLN-SUBMITTAL-SUPPLEMENTAL FORMS': 'PLN-SUBMITTAL-SUPPLEMENTAL FORMS',
    'ACA-Energy Calculations': 'ACA-Energy Calculations',
    'ACA-Other': 'ACA-Other',
    'BLD-Application or Issuance Report': 'BLD-Application or Issuance Report',
    'PLN-Correspondence-Staff Report': 'PLN-Correspondence-Staff Report',
    'PLN-Submittal-Photographs': 'PLN-Submittal-Photographs',
    'ACA-OTHER': 'ACA-OTHER',
    'PLN-General': 'PLN-General',
    'PLN-Submittal-Application': 'PLN-Submittal-Application',
    'PLN-CORRESPONDENCE-STAFF REPORT': 'PLN-CORRESPONDENCE-STAFF REPORT',
    'PLN-ACA-General': 'PLN-ACA-General',
    'Application': 'Application',
    'Photographs': 'Photographs',
    'CE-General-NOV-Issued': 'CE-General-NOV-Issued',
    'PLN-EMAIL NOTIFICATION': 'PLN-EMAIL NOTIFICATION',
    'Plans': 'Plans',
    'Supplemental Forms': 'Supplemental Forms',
}

export const OakZones = {
    'RU-3/S-20': 'RU-3/S-20',
    'RU-3/S-7/S-12': 'RU-3/S-7/S-12',
    'OS (SU)': 'OS (SU)',
    'CBD-P': 'CBD-P',
    'RM-2': 'RM-2',
    'RM-3/S-12': 'RM-3/S-12',
    'OS (CP)': 'OS (CP)',
    'CBD-P/CH': 'CBD-P/CH',
    'RH-3': 'RH-3',
    'OS(NP)/S-20': 'OS(NP)/S-20',
    'RU-1': 'RU-1',
    'M-40': 'M-40',
    'D-KP-4': 'D-KP-4',
    'S-3': 'S-3',
    'C-40': 'C-40',
    'RM-4/S-20': 'RM-4/S-20',
    'CBD-P/S-7': 'CBD-P/S-7',
    'RM-4/D-BR': 'RM-4/D-BR',
    'RU-3/S-12': 'RU-3/S-12',
    'RH-2/S-10/S-11': 'RH-2/S-10/S-11',
    'OS (RSP)': 'OS (RSP)',
    'OS (AF)': 'OS (AF)',
    'CC-3': 'CC-3',
    'RH-4/S-10': 'RH-4/S-10',
    'CN-2': 'CN-2',
    'HBX-1': 'HBX-1',
    'CN-3/D-KP-1': 'CN-3/D-KP-1',
    'RD-2': 'RD-2',
    'HBX-2': 'HBX-2',
    'OS (NP)/S-12': 'OS (NP)/S-12',
    'RH-3/S-10': 'RH-3/S-10',
    'RU-4': 'RU-4',
    'RM-3/C': 'RM-3/C',
    'RD-1/S-20': 'RD-1/S-20',
    'RD-2/S-20': 'RD-2/S-20',
    'D-KP-1': 'D-KP-1',
    'OS (LP)': 'OS (LP)',
    'CR-1': 'CR-1',
    'RH-1/S-11': 'RH-1/S-11',
    'RH-4/S-10/S-11': 'RH-4/S-10/S-11',
    'OS (RCA)/S-11': 'OS (RCA)/S-11',
    'RM-2/S-10': 'RM-2/S-10',
    'M-40/S-4': 'M-40/S-4',
    'R-80': 'R-80',
    'RM-3': 'RM-3',
    'OS (RCA)': 'OS (RCA)',
    'CN-2/D-BR': 'CN-2/D-BR',
    'RH-4/S-11': 'RH-4/S-11',
    'RU-1/S-12': 'RU-1/S-12',
    'C-45/S-4': 'C-45/S-4',
    'RM-4/C/S-20': 'RM-4/C/S-20',
    'OS (AMP)': 'OS (AMP)',
    'S-1': 'S-1',
    'CC-2/D-BR': 'CC-2/D-BR',
    'RM-2/C': 'RM-2/C',
    'D-KP-2': 'D-KP-2',
    'RH-1': 'RH-1',
    'CN-3/S-12': 'CN-3/S-12',
    'OS (NP)/S-10': 'OS (NP)/S-10',
    'CN-4': 'CN-4',
    'CN-1': 'CN-1',
    'OS (RCA)/S-10/S-11': 'OS (RCA)/S-10/S-11',
    'CC-2/S-7': 'CC-2/S-7',
    'CBD-R/S-7': 'CBD-R/S-7',
    'HBX-3': 'HBX-3',
    'S-2': 'S-2',
    'RM-4/S-12': 'RM-4/S-12',
    'CBD-C': 'CBD-C',
    'CN-2/D-KP-3': 'CN-2/D-KP-3',
    'OS (NP)': 'OS (NP)',
    'RM-3/S-20': 'RM-3/S-20',
    'RH-2': 'RH-2',
    'RH-3/S-10/S-11': 'RH-3/S-10/S-11',
    'CC-2': 'CC-2',
    'RU-1/S-20': 'RU-1/S-20',
    'IG/S-19': 'IG/S-19',
    'RD-1': 'RD-1',
    'RM-3/D-BR': 'RM-3/D-BR',
    'OS(PMP)': 'OS(PMP)',
    'OS (RCA)/S-10': 'OS (RCA)/S-10',
    'RH-1/S-10': 'RH-1/S-10',
    'CIX-2': 'CIX-2',
    'CN-2/S-12': 'CN-2/S-12',
    'RH-1/S-10/S-11': 'RH-1/S-10/S-11',
    'CIX-2/S-19': 'CIX-2/S-19',
    'M-30': 'M-30',
    'C-45': 'C-45',
    'RH-3/S-11': 'RH-3/S-11',
    'CBD-X': 'CBD-X',
    'RM-1/S-12': 'RM-1/S-12',
    'RM-2/S-20': 'RM-2/S-20',
    'RU-3/D-BR': 'RU-3/D-BR',
    'RM-4/C': 'RM-4/C',
    'RM-1': 'RM-1',
    'RH-4': 'RH-4',
    'IO': 'IO',
    'S-15': 'S-15',
    'RU-2': 'RU-2',
    'OS (NP)/S-4': 'OS (NP)/S-4',
    'OS (NP)/S-20': 'OS (NP)/S-20',
    'CIX-1/S-19': 'CIX-1/S-19',
    'RU-5': 'RU-5',
    'CIX-1': 'CIX-1',
    'OS (SU)/S-4': 'OS (SU)/S-4',
    'OS (LP)/S-10': 'OS (LP)/S-10',
    'RM-4': 'RM-4',
    'RU-4/D-BR': 'RU-4/D-BR',
    'OS (PMP)': 'OS (PMP)',
    'IG': 'IG',
    'CN-3/S-7': 'CN-3/S-7',
    'D-KP-3': 'D-KP-3',
    'OS (RCA)/S-4': 'OS (RCA)/S-4',
    'RH-2/S-11': 'RH-2/S-11',
    'OS(LP)/S-4': 'OS(LP)/S-4',
    'RU-2/S-12': 'RU-2/S-12',
    'CN-3': 'CN-3',
    'RM-2/S-7': 'RM-2/S-7',
    'RH-2/S-10': 'RH-2/S-10',
    'OS(CP)/S-20': 'OS(CP)/S-20',
    'M-20/S-4': 'M-20/S-4',
    'CBD-R': 'CBD-R',
    'OS (LP)/S-4': 'OS (LP)/S-4',
    'CC-1': 'CC-1',
    'RU-3': 'RU-3',
    'D-OTN': 'D-OTN',
    'Wood Street': 'Wood Street',
    'CBD-X/S-7': 'CBD-X/S-7',
}

export const OakGpClassifications = {
    'Mixed Housing Type Residential': 'Mixed Housing Type Residential',
    'General Industry and Transportation': 'General Industry and Transportation',
    'Urban Park and Open Space': 'Urban Park and Open Space',
    'Detached Unit Residential': 'Detached Unit Residential',
    'Resource Conservation': 'Resource Conservation',
    'Regional Commercial': 'Regional Commercial',
    'Hillside Residential': 'Hillside Residential',
    'Community Commercial': 'Community Commercial',
    'Estuary Policy Plan Area': 'Estuary Policy Plan Area',
    'Business Mix': 'Business Mix',
    'Institutional': 'Institutional',
    'Central Business District': 'Central Business District',
    'Housing and Business Mix': 'Housing and Business Mix',
    'Neighborhood Center Mixed Use': 'Neighborhood Center Mixed Use',
    'Urban Residential': 'Urban Residential',
}

export const DistanceMile = [
    {value: "0", display: "Any distance"},
    {value: "0.125", display: "Within 1/8 mile"},
    {value: "0.25", display: "Within 1/4 mile"},
    {value: "0.5", display: "Within 1/2 mile"},
    {value: "0.75", display: "Within 3/4 mile"},
    {value: "1.0", display: "Within 1 mile"},
    {value: "1.25", display: "Within 1 1/4 miles"},
    {value: "1.5", display: "Within 1 1/2 miles"},
    {value: "1.75", display: "Within 1 3/4 miles"},
    {value: "2", display: "Within 2 miles"},
    {value: "2.5", display: "Within 2 1/2 miles"},
    {value: "3", display: "Within 3 miles"},
    {value: "3.5", display: "Within 3 1/2 miles"},
    {value: "4", display: "Within 4 miles"},
    {value: "4.5", display: "Within 4 1/2 miles"},
    {value: "5", display: "Within 5 miles"},
    {value: "5.5", display: "Within 5 1/2 miles"},
    {value: "6", display: "Within 6 miles"},
    {value: "6.5", display: "Within 6 1/2 miles"},
]