/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, useRef, useEffect } from "react";
import { OAK_PLANNING_DETAIL } from 'utils/constants';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Form, Spinner, Modal, ProgressBar, ToggleButtonGroup, ToggleButton, Dropdown, DropdownButton } from 'react-bootstrap';
import { generic_get_api } from "api/generic_api";
import left_line from 'assets/reputation/left-line.svg';
import right_line from 'assets/reputation/right-line.svg';
import middle_line from 'assets/reputation/middle-line.svg';



class OakPlanningDetail extends Component {
    
    constructor(props) {
        
        let record_id = null;
        let path_components = window.location.pathname.split('/')
        record_id = path_components[path_components.length - 1]

        super(props);

        this.state = {
            record_id: record_id,
            record_details: null,
        };

        this.fetchRecord = this.fetchRecord.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'oak_planning_detail'});
        this.fetchRecord();
    }

    fetchRecord() {

        if (!this.state.record_id){
            return;
        }

        var request = { 
            record_id: this.state.record_id,
        }

        generic_get_api(OAK_PLANNING_DETAIL, request)
        .then(
            data => { 
                this.setState({
                    record_details: data,
                })
            }
        )
        .catch(error => {
        });
    }

    render() {

        const {record_details} = this.state;
        
        return(
            <div class="container sfPlanningDetail">

                {
                    record_details &&
                        <div class="container detailSection">
                            <div class="row">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col fs-5 fw-bold">{record_details['record_number']}</div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col">
                                                    {record_details['application_date']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                                                    {record_details['record_type']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;{record_details['current_status']}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-message"></i></div>
                                        <div class="col">{record_details['description']}</div>
                                    </div>    
                                
                                    {
                                        record_details['related_record_types'] && record_details['related_record_types'].length > 0 &&
                                        <div class="row mt-3">
                                            <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-folder"></i></div>
                                            <div class="col">
                                                {
                                                    record_details['related_record_types'].map((related_type, idx) => {
                                                        if (idx < record_details['related_record_types'].length - 1){
                                                            return <>{related_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</>
                                                        }
                                                        else{
                                                            return <>{related_type}</>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        record_details['file_types'] && record_details['file_types'].length > 0 &&
                                        <div class="row mt-3">
                                            <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-file"></i></div>
                                            <div class="col">
                                                {
                                                    record_details['file_types'].map((file_type, idx) => {
                                                        if (idx < record_details['file_types'].length - 1){
                                                            return <>{file_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</>
                                                        }
                                                        else{
                                                            return <>{file_type}</>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                        
                                </div>

                                {
                                    (record_details['address']['street_name'] || record_details['zone'] || record_details['gp_classifcation']) &&

                                    <div class="col-4 propertyInfoColumn">
                                        <div class="row">
                                            <div class="col-auto pe-0"><i class="fa-solid fa-location-dot"></i></div>
                                            <div class="col">
                                                <div class="row"><div class="col">{record_details['address']['street_number']} {record_details['address']['street_name']} {record_details['address']['street_suffix']}</div></div>
                                                <div class="row"><div class="col">San Francisco {record_details['address']['postal']}</div></div>
                                            </div>
                                        </div>
                                        {
                                            record_details['zone'] &&
                                        
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-ruler-vertical"></i></div>
                                                <div class="col">
                                                    <div class="row"><div class="col">
                                                        {record_details['zone']}
                                                    </div></div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            record_details['gp_classification'] &&
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-regular fa-building"></i></div>
                                                <div class="col">
                                                    <div class="row"><div class="col">
                                                        {record_details['gp_classification']}
                                                    </div></div>
                                                </div>
                                            </div>
                                        }
                                        
                                    </div>
                                }
                            </div>
                            <FileSection file_groups={record_details['file_groups']} />

                        {   
                            record_details['related_records'] && record_details['related_records'].length > 0 &&
                            <>
                                <div class="row mt-5">
                                    <div class="col fw-semibold text-success fs-6"><i class="fa-regular fa-folder"></i>&nbsp;Sub-records</div>
                                </div>
                                <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
                                <div class="row mt-1">
                                {
                                    record_details['related_records'].map(related_record => {
                                        return  <> 
                                                    <RelatedRecordSection related_record={related_record} />
                                                    <div class="row"><div class="col pe-5 ps-5"><hr></hr></div></div>
                                                </>
                                    })
                                }
                                </div>
                            </>
                        }
                        </div>
                }
            </div>
        )
    }
}

function FileSection(props) {
    const {file_groups} = props;
    const [expand_map, setExpandMap] = useState({});

    const toggleState = (class_name) => {
        setExpandMap(prevState => ({
            ...prevState,
            [class_name]: !expand_map[class_name],
        }));
    }

    if (!file_groups || file_groups.length == 0){
        return null;
    }

    return(
        <div class="">
            <div class="row mt-5">
                <div class="col fw-semibold text-success fs-6"><i class="fa-regular fa-file-lines"></i>&nbsp;Available documents</div>
            </div>
            <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
            <div class="row mt-1">
                {file_groups.map(file_group => {
                    return  <div class="row mt-2  border p-2 ms-2">
                                <div class="row fw-bold">
                                    <div class="col">
                                        <a onClick={() => {toggleState(file_group.class_name)}} role="button">
                                            {
                                                expand_map[file_group.class_name] ?
                                                    <i class="fa-regular fa-square-minus fa-lg"></i>
                                                :
                                                <i class="fa-regular fa-square-plus fa-lg"></i>
                                            }
                                        
                                        </a>
                                        &nbsp;{file_group.class_name}&nbsp;({file_group.files.length}) 
                                    </div>
                                </div>
                                {
                                    expand_map[file_group.class_name] &&
                                    <>
                                        {
                                            file_group.files.map(file => {
                                                return <div class="row mt-1 ms-4"><span><a target="_blank" className="text-decoration-none text-secondary" role="button" href={file.signed_url}><i class="fa-regular fa-file"></i>&nbsp;{file.name}</a></span> </div>
                                            })
                                        }
                                    </>
                                }
                                
                            </div>
                })}
            </div>
        </div>
    )
}

function RelatedRecordSection(props) {

    const {related_record} = props;

    return(
        <div class="container">
            <div class="row">
                <div class="col">
                    {related_record['record_number']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['record_type']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['application_date']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['current_status']}
                    
                </div>
            </div>
            {
                related_record['description'] && related_record['description'].length > 0 &&
                <div class="row mt-2">
                    <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-message"></i></div>
                    <div class="col">{related_record['description']}</div>
                </div>
            }
           
        </div>
    )
}

export default OakPlanningDetail;