/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, useRef, useEffect } from "react";
import { SF_PLANNING_DETAIL } from 'utils/constants';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Form, Spinner, Modal, ProgressBar, ToggleButtonGroup, ToggleButton, Dropdown, DropdownButton } from 'react-bootstrap';
import { generic_get_api } from "api/generic_api";
import GoogleMapReact from 'google-map-react';
import left_line from 'assets/reputation/left-line.svg';
import right_line from 'assets/reputation/right-line.svg';
import middle_line from 'assets/reputation/middle-line.svg';
import { truncateText } from 'utils/helpers';
import { SfPlanningRecordType, SfPlanningFileType, SfHeightDistrict, SfSpecialUseDistrict, SfUseDistrict, DistanceMile} from 'utils/sf_planning_constants';



class SfPlanningDetail extends Component {
    
    constructor(props) {
        
        let record_id = null;
        let path_components = window.location.pathname.split('/')
        record_id = path_components[path_components.length - 1]

        super(props);

        this.state = {
            record_id: record_id,
            record_details: null,
        };

        this.fetchRecord = this.fetchRecord.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_detail'});
        this.fetchRecord();
    }

    fetchRecord() {

        if (!this.state.record_id){
            return;
        }

        var request = { 
            record_id: this.state.record_id,
        }

        generic_get_api(SF_PLANNING_DETAIL, request)
        .then(
            data => { 
                this.setState({
                    record_details: data,
                })
            }
        )
        .catch(error => {
        });
    }

    render() {

        const {record_details} = this.state;
        const items = [
            {
              title: "example",
              cardTitle: "example",
              cardSubtitle: "example",
              cardDetailedText: "example",
            }
          ];
        return(
            <div class="container sfPlanningDetail">

                {
                    record_details &&
                        <div class="container detailSection">
                            <div class="row">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col fs-5 fw-bold">{record_details['record_number']}</div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col">
                                                    {record_details['application_date']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                                                    {record_details['record_type']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;{record_details['current_status']}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-message"></i></div>
                                        <div class="col">{record_details['description']}</div>
                                    </div>    
                                
                                    {
                                        record_details['related_record_types'] && record_details['related_record_types'].length > 0 &&
                                        <div class="row mt-3">
                                            <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-folder"></i></div>
                                            <div class="col">
                                                {
                                                    record_details['related_record_types'].map((related_type, idx) => {
                                                        if (idx < record_details['related_record_types'].length - 1){
                                                            return <>{related_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</>
                                                        }
                                                        else{
                                                            return <>{related_type}</>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        record_details['file_types'] && record_details['file_types'].length > 0 &&
                                        <div class="row mt-3">
                                            <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-file"></i></div>
                                            <div class="col">
                                                {
                                                    record_details['file_types'].map((file_type, idx) => {
                                                        if (idx < record_details['file_types'].length - 1){
                                                            return <>{file_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</>
                                                        }
                                                        else{
                                                            return <>{file_type}</>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                        
                                </div>

                                {
                                    (record_details['address']['street_name'] || record_details['use_district'] || record_details['height_district'] || record_details['special_use_districts'].length > 0) &&

                                    <div class="col-4 propertyInfoColumn">
                                        <div class="row">
                                            <div class="col-auto pe-0"><i class="fa-solid fa-location-dot"></i></div>
                                            <div class="col">
                                                <div class="row"><div class="col">{record_details['address']['street_number']} {record_details['address']['street_name']} {record_details['address']['street_suffix']}</div></div>
                                                <div class="row"><div class="col">San Francisco {record_details['address']['postal']}</div></div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-auto pe-0"><i class="fa-solid fa-ruler-vertical"></i></div>
                                            <div class="col">
                                                <div class="row"><div class="col">
                                                    {record_details['use_district']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;{record_details['height_district']}
                                                </div></div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-auto pe-0"><i class="fa-regular fa-building"></i></div>
                                            <div class="col">
                                                {
                                                    record_details['special_use_districts'].map((district, idx) => {
                                                        if (idx < record_details['special_use_districts'].length - 1) {
                                                            return <>{district}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</>
                                                        }
                                                        else{
                                                            return <>{district}</>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                            <InsightComponent insight={record_details['insight']}/>
                            <FileSection file_groups={record_details['file_groups']} />

                        {   
                            record_details['related_records'] && record_details['related_records'].length > 0 &&
                            <>
                                <div class="row mt-5">
                                    <div class="col fw-semibold text-success fs-6"><i class="fa-regular fa-folder"></i>&nbsp;Sub-records</div>
                                </div>
                                <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
                                <div class="row mt-1">
                                {
                                    record_details['related_records'].map(related_record => {
                                        return  <> 
                                                    <RelatedRecordSection related_record={related_record} />
                                                    <div class="row"><div class="col pe-5 ps-5"><hr></hr></div></div>
                                                </>
                                    })
                                }
                                </div>
                            </>
                        }
                        </div>
                }
            </div>
        )
    }
}

function FileSection(props) {
    const {file_groups} = props;
    const [expand_map, setExpandMap] = useState({});

    const toggleState = (class_name) => {
        setExpandMap(prevState => ({
            ...prevState,
            [class_name]: !expand_map[class_name],
        }));
    }

    if (!file_groups || file_groups.length == 0){
        return null;
    }

    return(
        <div class="">
            <div class="row mt-5">
                <div class="col fw-semibold text-success fs-6"><i class="fa-regular fa-file-lines"></i>&nbsp;Available documents</div>
            </div>
            <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
            <div class="row mt-1">
                {file_groups.map(file_group => {
                    return  <div class="row mt-2  border p-2 ms-2">
                                <div class="row fw-bold">
                                    <div class="col">
                                        <a onClick={() => {toggleState(file_group.class_name)}} role="button">
                                            {
                                                expand_map[file_group.class_name] ?
                                                    <i class="fa-regular fa-square-minus fa-lg"></i>
                                                :
                                                <i class="fa-regular fa-square-plus fa-lg"></i>
                                            }
                                        
                                        </a>
                                        &nbsp;{file_group.class_name}&nbsp;({file_group.files.length}) 
                                    </div>
                                </div>
                                {
                                    expand_map[file_group.class_name] &&
                                    <>
                                        {
                                            file_group.files.map(file => {
                                                return <div class="row mt-1 ms-4"><span><a target="_blank" className="text-decoration-none text-secondary" role="button" href={file.signed_url}><i class="fa-regular fa-file"></i>&nbsp;{file.name}</a></span> </div>
                                            })
                                        }
                                    </>
                                }
                                
                            </div>
                })}
            </div>
        </div>
    )
}

function RelatedRecordSection(props) {

    const {related_record} = props;

    return(
        <div class="container">
            <div class="row">
                <div class="col">
                    {related_record['record_number']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['record_type']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['application_date']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['current_status']}
                    
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-message"></i></div>
                <div class="col">{related_record['description']}</div>
            </div>
        </div>
    )
}

function InsightComponent(props) {

    const {insight } = props;

    return (
        <div class="derivedSection mt-5">
           
            <div class="row">
                <div class="col fw-bold text-success fs-6"><i class="fa-solid fa-wand-magic-sparkles"></i>&nbsp;Insights</div>
            </div>
            <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
            {
                insight['time_line'] && insight['time_line'].length > 1 &&
                <div class="row mb-4 mt-3">
                    <TimeLine
                        time_line={insight['time_line']}
                    />
                </div>
            }
            {/* <div class="row mt-3">
                {
                    insight['has_plan_check_letter'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Plan check comments</small></div>
                }
                {
                    insight['has_appeal'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Appealed</small></div>
                }
                {
                    insight['has_opposition_position'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Received opposition</small></div>
                }

            </div> */}
            <VarInsightComponent var_insight={insight['var_insight']} />
            <ZadInsight zad_insight={insight['zad_insight']} />
            <CuaInsight cua_insight={insight['cua_insight']} />
            <PclSectionComponent pcl_items={insight['pcl_items']} />
            <IncompleteItemsComponent incomplete_items={insight['incomplete_items']} />
            <PositionInsight opposition_positions={insight.opposition_positions} support_positions={insight.support_positions} />
        </div>
    )
}

function PositionInsight(props) {
    const {opposition_positions, support_positions} = props;
    const[effective_list, setEffectiveList] = useState(null);
    const[position_text, setPositionText] = useState('');

    if ((!opposition_positions || opposition_positions.length == 0) && (!support_positions || support_positions.length == 0)){
        return null;
    }

    return(
            <div class="row positionInsight mt-3">
                <div class="row fw-bold"><div class="col">Support &amp; opposition</div></div>
                <div class="row mt-1">
                    <div class="col">
                        Opposition positions ({opposition_positions.length}) &nbsp;<a role="button" className="text-decoration-underline" onClick={()=>{setEffectiveList(opposition_positions); setPositionText('Opposition')}}>View</a>
                    </div>
                    <div class="col">
                        ({support_positions.length}) Support positions&nbsp;<a role="button" className="text-decoration-underline" onClick={()=>{setEffectiveList(support_positions); setPositionText('Support')}}>View</a>
                    </div>
                </div>

                <Modal
                    show={effective_list}
                    onHide={() => setEffectiveList(null)}
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{position_text} statements</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="container positionModal">
                        {
                            effective_list && effective_list.map(position=> {
                                return <div class="row mt-3"><div class="col">{position}</div></div>
                            })
                        }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
    )
}

function CuaInsight(props) {
    const {cua_insight} = props;

    if (!cua_insight){
        return null;
    }

    return (
        <div class="row zadInsight border rounded mt-4 ms-1 p-2">
            <div class="row fw-bold fs-6"><div class="col">Conditional use insights</div></div>
            <div class="row mt-2">
                <div class="col">
                    <i class="fa-solid fa-gavel"></i>&nbsp;{cua_insight['committee_recommendation']}&nbsp;&nbsp;
                    &#x2022;&nbsp;&nbsp;
                    <i class="fa-solid fa-calendar-days"></i>&nbsp;{cua_insight['hearing_date']}
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-auto"><i class="fa-solid fa-list"></i>&nbsp;Application summary</div>
            </div>
            <div class="row mt-2">
                <div class="row"><div class="col">{cua_insight['project_description']}</div></div>
                <div class="row mt-2"><div class="col">{cua_insight['present_use']}</div></div>
                <div class="row mt-2"><div class="col">{cua_insight['surrounding']}</div></div>
                <div class="row mt-2"><div class="col">{cua_insight['public_comments']}</div></div>
                <div class="row mt-2"><div class="col">{cua_insight['environmental_review']}</div></div>
            </div>

            <div class="row mt-4"><div class="col"><i class="fa-solid fa-scale-balanced"></i>&nbsp;Findings</div></div>
            <div class="row mt-2">
                <div class="col">
                    <div class="row"><div class="col text-secondary"><i>Conditional use evaluation</i></div></div>
                    <div class="row"><div class="col">{cua_insight['conditional_use_evaluation']}</div></div>
                </div>
            </div>
            {
                cua_insight['issues'] && cua_insight['issues'].length > 0 &&
                <>
                    {
                        cua_insight['issues'].map(issue=>{
                            return  <div class="row mt-2">
                                        <div class="row"><div class="col text-secondary"><i>{issue[0]}</i></div></div>
                                        <div class="row"><div class="col">{issue[1]}</div></div>
                                    </div>
                        })
                    }
                </>
            }
            
            {
                cua_insight['planning_code_findings'] && cua_insight['planning_code_findings'].length > 0 &&
                <>  
                {
                    cua_insight['planning_code_findings'].map(pcf=>{
                        return  <div class="row mt-2">
                                    <div class="row"><div class="col text-secondary"><i>{pcf[0]}</i></div></div>
                                    <div class="row"><div class="col">{pcf[1]}</div></div>
                                </div>
                    })
                }
                </>
            }
            
        </div>
    )
}

function PclSectionComponent(props) {
    const {pcl_items} = props;

    if (!pcl_items || pcl_items.length == 0) {
        return null;
    }

    return(
        <div class="row pclInsight border rounded mt-4 ms-1 p-2">
            <div class="row  fw-bold fs-6"><div class="col">Plan checker comments</div></div>
            {
                pcl_items.map(pcf => { 
                            return  <div class="mt-2">
                                        <div class="row"><div class="col text-secondary"><i>{pcf[0]}</i></div></div>
                                        <div class="row"><div class="col">{pcf[1]}</div></div>
                                    </div>
                })
            }
        </div>
    );

}

function IncompleteItemsComponent(props) {
    const {incomplete_items} = props;

    if (!incomplete_items || incomplete_items.length == 0) {
        return null;
    }

    return(
        <div class="row incompleteItemsInsight border rounded mt-4 ms-1 p-2">
            <div class="row  fw-bold fs-6"><div class="col">Incomplete items</div></div>
            {
                incomplete_items.map(item => { 
                            return  <div class="mt-2">
                                        <div class="row"><div class="col fw-bold text-secondary"><small>{item[0]}</small></div></div>
                                        <div class="row"><div class="col">{item[1]}</div></div>
                                    </div>
                })
            }
        </div>
    );

}

function VarInsightComponent(props){
    const {var_insight} = props;
    const [show_variance_findings, setShowVarianceFindings] = useState(false);

    if (! var_insight){
        return null;
    }

    return(         
            <div class="row varInsight border rounded mt-4 ms-1 p-2">
                <div class="row  fw-bold fs-6"><div class="col">Variance insights</div></div>
           
                <div class="row mt-2">
                    <div class="col">
                        {var_insight['variance_sought']}&nbsp;&nbsp;
                        &#x2022;&nbsp;&nbsp;
                        <i class="fa-solid fa-gavel"></i>&nbsp;{var_insight['decision']}&nbsp;&nbsp;
                        &#x2022;&nbsp;&nbsp;
                        <i class="fa-solid fa-calendar-days"></i>&nbsp;{var_insight['hearing_date']}&nbsp;&nbsp;
                        &#x2022;&nbsp;&nbsp;
                        {
                            var_insight['planning_code_sections'] && var_insight['planning_code_sections'].length > 0 ?
                            <><i class="fa-solid fa-section"></i>&nbsp;{var_insight['planning_code_sections'].join(", ")}</> :
                            <span><i class="fa-regular fa-circle-xmark fa-sm"></i> None</span>
                        }
                    </div>
                </div>
            
            {
                var_insight['findings'] && var_insight['findings'].length > 0 &&
                <div class="">
                {
                    show_variance_findings ? 
                        <>
                            <div class="row mb-2">
                                <div class="col fw-bold text-secondary">Variance findings <a className="text-decoration-underline" role="button" onClick={()=>setShowVarianceFindings(false)}><small>hide</small></a></div>
                            </div>
                            {
                                var_insight['findings'].map(finding_entry => {
                                    return  <div class="mb-3">
                                                <div class="row"><div class="col fst-italic">{finding_entry[0]}</div></div>
                                                <div class="row"><div class="col mt-2">{finding_entry[1]}</div></div>
                                                <div class="row"><div class="col mt-2">Result: <span className="fw-semibold">{finding_entry[2]}</span></div></div>
                                            </div>
                                })
                            }
                        </>
                    :
                    <>
                        <div class="row mb-2">
                            <div class="col fw-bold text-secondary"><i class="fa-solid fa-caret-right"></i>&nbsp;Variance findings <a className="text-decoration-underline" role="button" onClick={()=>setShowVarianceFindings(true)}><small>show</small></a></div>
                        </div>
                    </>
                }
                </div>
                
            }
        </div>
    )
}

function ZadInsight(props) {
    const {zad_insight} = props;

    if (!zad_insight){
        return null;
    }

    return (
        <div class="row zadInsight border rounded mt-4 ms-1 p-2">
            <div class="row  fw-bold fs-6"><div class="col">ZA determination insights</div></div>
        
            <div class="row mt-2">
                <div class="col">
                    {
                        zad_insight.determination && <><i class="fa-solid fa-calendar-days"></i>&nbsp;{zad_insight['document_date']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</>
                    }
                    {
                        zad_insight['planning_code_sections'] && zad_insight['planning_code_sections'].length &&
                        <><i class="fa-solid fa-section"></i>&nbsp;{zad_insight['planning_code_sections'].join(", ")}</>
                    }
                </div>
            </div>
            {
                zad_insight['request'] && zad_insight['request'].length > 0 && 
                <div class="row mt-3">
                    <div class="col-auto pe-0"><i class="fa-solid fa-clipboard-question"></i></div>
                    <div class="col">{zad_insight['request']}</div>
                </div>
            }
             {
                zad_insight['determination'] && zad_insight['determination'].length > 0 && 
                <div class="row mt-3">
                    <div class="col-auto pe-0"><i class="fa-solid fa-gavel"></i></div>
                    <div class="col">{zad_insight['determination']}</div>
                </div>
            }
        </div>
    )
}

function TimeLine(props) {
    const {time_line} = props;

    if (time_line.length <= 0){
        return null;
    }

    return (                                            
        <div class="container d-flex justify-content-center timeLine">
            <div class="row">
                <div class="col">
                    <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{time_line[0][0]}</small></div></div>
                    <div class="row mt-1 mb-1"><div class="col p-0"><img src={left_line}/></div></div>
                    <div class="row text-center"><div class="col p-0"><small>{time_line[0][1]}</small></div></div>
                </div>
                {
                    time_line.map((entry, index) => {
                        if (index > 0 && index < time_line.length - 1){
                            return  <div class="col">
                                        <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{entry[0]}</small></div></div>
                                        <div class="row  mt-1 mb-1"><div class="col p-0"><img src={middle_line}/></div></div>
                                        <div class="row text-center"><div class="col p-0"><small>{entry[1]}</small></div></div>
                                    </div>
                        }
                            
                    })
                }
                <div class="col">
                    <div class="row text-center text-wrap"><div class="col p-0 width85"><small>{time_line[time_line.length - 1][0]}</small></div></div>
                    <div class="row mt-1 mb-1"><div class="col p-0"><img src={right_line}/></div></div>
                    <div class="row text-center"><div class="col p-0"><small>{time_line[time_line.length - 1][1]}</small></div></div>
                </div>
            </div>
        </div>);
}

export default SfPlanningDetail;