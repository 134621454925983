import React, { Component } from "react";

import Footer from "pages/footer";
import { INFO_REQUEST_URL } from 'utils/constants'
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import { FE_URL } from "utils/constants";
import logo from 'assets/reputation/logo_green.svg';
import documents_green from 'assets/reputation/documents_green.svg';
import one_documents_green from 'assets/reputation/one_document_green.svg';
import timer_green from 'assets/reputation/timer_green.svg';
import streamline_green from 'assets/reputation/streamline_green.svg';
import search_green from 'assets/reputation/search_green.svg';
import records_green from 'assets/reputation/records_green.svg';
import access_green from 'assets/reputation/access_green.svg';
import patterns_green from 'assets/reputation/patterns_green.svg';
import clip_board from 'assets/reputation/clip_board.svg';
import monitor_green from 'assets/reputation/monitor_green.svg';
import laptop_green from 'assets/reputation/laptop_green.svg';

import arch_green from 'assets/reputation/arch_green.svg';
import eng_green from 'assets/reputation/eng_green.svg';
import dev_green from 'assets/reputation/dev_green.svg';
import lawyer_green from 'assets/reputation/lawyer_green.svg';
import planner_green from 'assets/reputation/planner_green.svg';
import surveyor_green from 'assets/reputation/surveyor_green.svg';

import one_green from 'assets/reputation/one_green.svg';
import two_green from 'assets/reputation/two_green.svg';
import three_green from 'assets/reputation/three_green.svg';

import one_green_mob from 'assets/reputation/one_green_mob.svg';
import two_green_mob from 'assets/reputation/two_green_mob.svg';

import typing_searching from 'assets/reputation/typing_searching.png';

import tool_screen_shot from 'assets/reputation/tool_screen_shot.svg';
import { Button, Spinner, Navbar, Container, Nav } from "react-bootstrap";
import { isValidEmail } from "utils/helpers";

class CodeLanding extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            show_contact_us_modal: false,
            name: "",
            company: "",
            email: "",
            phone: "",
            submitted: false,
            showSpinner: false,
            displayContactUs: false,
        };

        this.formChanged = this.formChanged.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.setDisplayContactUs = this.setDisplayContactUs.bind(this);
    }

    setDisplayContactUs(value) {
        this.setState({displayContactUs: value});
    }

    handleSubmitRequest() {

        var request = { 
            name: this.state.name,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(INFO_REQUEST_URL);
        this.setState({ showSpinner: true })

        trackEvent(EventNames.API_REQUEST, {'data_1': request, "data_2": INFO_REQUEST_URL})

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {
                    this.setState({
                        submitted: true,
                        showSpinner: false
                    })
                }
                else {
                    this.setState({
                        showSpinner: false
                    })
                }
            }
        );
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'code_landing'})
    }

    formChanged(e, element) {
        this.setState({ [element]: e.target.value })
    }

    render() {
        const canSubmit = () => {
            return this.state.name && this.state.email && this.state.phone && isValidEmail(this.state.email) && this.state.company;
        }

        return(
            <div class="codeLandingEnvelope"  id="top-of-page">
                <ContactUs 
                        displayStatus={ this.state.displayContactUs } 
                        changeDisplayStatus={ this.setDisplayContactUs }
                />
                <div class="codeLandingHeader pt-1 ps-5 pe-5 pb-5">
                    <div class="container">
                        <Navbar id="navBar" collapseOnSelect expand="md" > 
                            <Container>
                                <Navbar.Brand href={FE_URL} className="me-3 mb-3"><img src={logo} class="brandLogo"/></Navbar.Brand>
                                    <Navbar.Collapse id="basic-navbar-nav" className="ms-5">
                                        <Nav className="me-auto ms-5">
                                            <Nav.Link href={FE_URL} eventKey="1" className="me-3 text-white">Home</Nav.Link>
                                            <Nav.Link href='/login' eventKey="2" className="me-3  text-white">Sign in</Nav.Link>
                                            <Nav.Link eventKey="3" onClick={() => this.setDisplayContactUs(true)} className="me-3  text-white">Contact us</Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <div class="row mt-5">
                            <div class="col text-center display-6 text-white">Entitlement intelligence</div>
                        </div>
                        <div class="row mt-5">
                            <div class="col text-center text-white fs-3"><mark>Streamline</mark> planning code research, compliance &amp; permitting</div>
                        </div>

                        <div class="row mt-5 toolScreenShot">
                            <div class="col mt-5 text-center ">
                                <img src={tool_screen_shot}/></div>
                        </div>

                        <div class="row mt-5 pt-5">
                            <div class="col text-center"><Button href="#moreInfoFormId" className="moreInfoButton">Request a demo <i class="fa-regular fa-circle-play fa-lg"></i></Button></div></div>
                    </div>
                </div>
                <div class="">
                    <div class="container problemDescription p-5">
                        
                        <div class="row">
                            <div class="col-12 col-md-7">
                                <div class="row">
                                    <div class="col fs-3 fw-bold"><mark>Reduce uncertainty</mark> and delays to optimize your projects</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div><img src={documents_green} class="cardIcon mt-3"/></div>
                                        <div class="fw-bold mt-3">Leverage past applications</div>
                                        <div  class="mt-2">Identify key patterns and precedents from historical applications to guide your project and improve outcomes.</div>
                                    </div>
                                    <div class="col">
                                        <div><img src={streamline_green} class="cardIcon mt-3"/></div>
                                        <div class="fw-bold mt-3">Streamline your projects</div>
                                        <div class="mt-2">Use a unified platform across multiple jurisdictions to access and manage data seamlessly.</div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div><img src={one_documents_green} class="cardIcon mt-3"/></div>
                                        <div class="fw-bold mt-3">Discover relevant codes and regulations</div>
                                        <div class="mt-2">Quickly pinpoint the planning and zoning codes, rules, and regulations specific to your project.</div>
                                    </div>
                                    <div class="col">
                                        <div><img src={timer_green} class="cardIcon mt-3"/></div>
                                        <div class="fw-bold mt-3">Reduce delays</div>
                                        <div class="mt-2">Avoid setbacks by utilizing proven insights and real-time data to keep your project on track.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 searching_img">
                                <div class="row mt-5">
                                    <div class="col mt-5"></div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col text-center"><img src={typing_searching} /></div>
                                </div>
                            
                            </div>
                        </div>

                      
                    </div>
                </div> 

                <div class="p-5 offer">
                    <div class="container">
                        <div class="row"> <div class="col text-center fs-3 fw-bold"><mark>Accelerate entitlement</mark> with data-driven insights</div></div>
                        <div class="row mt-5 gy-4">
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="container cardWithBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={search_green} class="cardIcon"/></div>
                                    </div>
                                    <div class="row mt-3 ps-1 pe-1">
                                        <div class="col fs-5  text-center">Discover similar projects to guide your strategy</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Employ advanced filters including decision, zoning, distance, type of project &amp; reports.</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Use natural language and Boolean queries.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="container cardWithBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={patterns_green} class="cardIcon"/></div>
                                    </div>
                                    <div class="row mt-3 ps-1 pe-1">
                                        <div class="col fs-5  text-center">Uncover patterns in entitlement decisions</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Identify common roadblocks and opportunities for flexibility.</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Gain insights into timelines, processing averages, and hidden restrictions.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="container cardWithBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={access_green} class="cardIcon"/></div>
                                    </div>
                                    <div class="row mt-3 ps-1 pe-1">
                                        <div class="col fs-5  text-center">Access comprehensive public records</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Review staff reports, commission motions, and plan checker comments.</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Browse applications, plans, and public notifications all in one place.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="container cardWithBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={records_green} class="cardIcon"/></div>
                                    </div>
                                    <div class="row mt-3 ps-1 pe-1">
                                        <div class="col fs-5 text-center">Retrieve any document</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Quickly access documents like conditional use permits, housing bonuses, environmental studies, and zoning verifications.</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-auto"><i class="fa-solid fa-check fa-sm"></i></div>
                                        <div class="col">Explore historical zoning determinations, violations, and enforcements to avoid surprises.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-5">
                    <div class="container">
                        <div class="row"> <div class="col text-center fs-3 fw-bold">Get relevant planning &amp; zoning <mark>code for your project</mark></div></div>
                        <div class="row mt-5 gy-5">
                            <div class="col-12 col-md-4">
                                <div class="container circleRight cardCircleBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={clip_board} class="cardIcon"/></div>
                                    </div>
                                    <div class="row ps-1 pe-1">
                                        <div class="col fs-5 text-center">Quickly identify key project restrictions.</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col  text-center">Instantly determine zoning, setbacks, building ratios, permitted uses, and more.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-4">
                                <div class="container circleTop cardCircleBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={monitor_green} class="cardIcon"/></div>
                                    </div>
                                    <div class="row ps-1 pe-1">
                                        <div class="col fs-5 text-center">Streamlined access to information</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col text-center">Access a unified interface for regulations across multiple jurisdictions.</div>
                                    </div>
                                    <div class="row"><div class="col p-0 ms-5 me-5"><hr/></div></div>
                                    <div class="row">
                                        <div class="col text-center">Easily locate planning codes, general plans, bulletins, and more.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="container circleBottom cardCircleBackground">
                                    <div class="row logoSection">
                                        <div class="col text-center mt-4 mb-4"><img src={laptop_green} class="cardIcon"/></div>
                                    </div>
                                    <div class="row ps-1 pe-1">
                                        <div class="col fs-5 text-center">Advanced search capabilities</div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col text-center">Leverage AI and Boolean queries to search within relevant documents.</div>
                                    </div>
                                    <div class="row"><div class="col p-0 ms-5 me-5"><hr/></div></div>
                                    <div class="row">
                                        <div class="col text-center">Get instant answers to code-related questions with your personal AI assistant.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="p-5 whoIsForSection">
                    <div class="container  text-white">
                        <div class="row"> <div class="col text-center fs-3 fw-bold"><mark>Who is</mark> this app for for?</div></div>
                        <div class="row mt-3 gy-5">
                            <div class="col-6 col-sm-4 col-lg-2">
                                <div class="container whoIsForCard">
                                    <div class="row"><div class="col text-center"><img src={arch_green} className="cardIcon65"/></div></div>
                                    <div class="row mt-2"><div class="col text-center fs-5">Architects</div></div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <div class="container whoIsForCard">
                                    <div class="row"><div class="col text-center"><img src={lawyer_green} className="cardIcon65"/></div></div>
                                    <div class="row mt-2"><div class="col text-center fs-5">Land-use attorneys</div></div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <div class="container whoIsForCard">
                                    <div class="row"><div class="col text-center"><img src={dev_green} className="cardIcon65"/></div></div>
                                    <div class="row mt-2"><div class="col text-center fs-5">Developers</div></div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <div class="container whoIsForCard">
                                    <div class="row"><div class="col text-center"><img src={eng_green} className="cardIcon65"/></div></div>
                                    <div class="row mt-2"><div class="col text-center fs-5">Engineers</div></div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <div class="container whoIsForCard">
                                    <div class="row"><div class="col text-center"><img src={planner_green} className="cardIcon65"/></div></div>
                                    <div class="row mt-2"><div class="col text-center fs-5">Urban planners</div></div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <div class="container whoIsForCard">
                                    <div class="row"><div class="col text-center"><img src={surveyor_green} className="cardIcon65"/></div></div>
                                    <div class="row mt-2"><div class="col text-center fs-5">Surveyors</div></div>
                                </div>
                            </div>
                            
                            
                        </div>
                       
                    </div>
                </div>
                <div class="finalSection mb-5">
                    <div class="container">
                        <div class="row mt-5">
                            <div class="col fs-3 fw-bold text-center"><mark>Why Choose</mark> Us?</div>
                        </div>
                        <div class="row mt-3 gy-5">
                            <div class="col-12 col-lg-6">
                                <div class="container  whyChooseUs p-3">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col numberCol text-center">
                                                <img src={one_green} className="numberDesktop numberImage"/>
                                                <img src={one_green_mob} className="numberMobile numberImage"/>
                                            </div>
                                            <div class="col">
                                                <div class="row fw-bold fs-5">Unlock expert-level insights</div>
                                                <div class="row mt-2">Leverage data-driven patterns and historical precedents for strategic decision-making.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col numberCol text-center">
                                                <img src={two_green} className="numberDesktop numberImage"/>
                                                <img src={two_green_mob} className="numberMobile numberImage"/>
                                            </div>
                                            <div class="col">
                                                <div class="row fw-bold fs-5">Save time and avoid delays</div>
                                                <div class="row mt-2">Speed up your project approvals with AI-powered research.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col numberCol text-center">
                                                <img src={three_green} className="numberImage"/>
                                            </div>
                                            <div class="col">
                                                <div class="row fw-bold fs-5">One platform, comprehensive access</div>
                                                <div class="row mt-2">Explore planning codes, zoning laws, and public records across jurisdictions—all in one place.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 ">
                                <div class="container infoForm p-3">
                                    { !this.state.submitted ?
                                        <div class="row" id="moreInfoFormId">
                                            <div class="col-12 text-center fs-4 text-white"> 
                                                Request a demo
                                            </div>
                                            <div class="col-12 mt-4"><input type="text" class="form-control" placeholder="Name" value={ this.name } onChange={ (e) => this.formChanged(e, 'name') }/></div>
                                            <div class="col-12 mt-3"><input type="text" class="form-control"  placeholder="Email"  value={ this.email } onChange={ (e) => this.formChanged(e, 'email') }/></div>
                                            <div class="col-12 mt-3"><input type="text" class="form-control"  placeholder="Phone"  value={ this.phone } onChange={ (e) => this.formChanged(e, 'phone') }/></div>
                                            <div class="col-12 mt-3">
                                                <select class="form-select" name="questionCategory" onChange={(e)=>this.formChanged(e, 'company' )}>
                                                    <option disabled selected value>Select your profession</option>
                                                    <option value="Attorney" >Attorney</option>
                                                    <option value="Architect" >Architect</option>
                                                    <option value="Developer" >Developer</option>
                                                    <option value="Other" >Other</option>
                                                </select></div>
                                            <div class="col-12 mt-5 text-center">
                                                { this.state.showSpinner ? 
                                                    <Button className="submit" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        SUBMITTING</Button>
                                                :
                                                    <Button className="submit" onClick={ this.handleSubmitRequest } disabled={!canSubmit()}>SUBMIT</Button>
                                                }
                                                
                                            </div>
                                        </div>
                                    :
                                        <div class="row text-center p-5">
                                            <div class="col text-white">Thank you for your submission. We will be in touch very soon.</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <Footer/>
            </div>
        )
    }
}

export default CodeLanding;