export const COMPANY_NAME = "Renolition"
export const COMPANY_NAME_INC = "Renolition Inc."
export const COMPANY_STREET = "201 Spear Street, STE 1100"
export const COMPANY_CITY = "San Francisco"
export const COMPANY_STATE = "California"
export const COMPANY_ZIP = "94105"


export const JobType = {
    BUILDING: 'building',
    PLUMBING: 'plumbing',
    ENGINEERING: 'engineer',
    ARCHITECT: 'architect'
}

export const EntityRole = {
    CONTRACTOR: 'CONTRACTOR',
    ENGINEER: 'ENGINEER',
    ARCHITECT: 'ARCHITECT',
    AGENT: 'AGENT',
    SF_BUSINESS: 'SF_BUSINESS',
    OTHER_BUSINESS: 'OTHER_BUSINESS',
    AGENT_CONTACT: 'AGENT_CONTACT',
    UNKNOWN: 'UNKNOWN',
}

export const WorkType = {
    NEW: 'new',
    REPAIR: 'repair'
}

export const WorkTypeDetail = {
    DOOR_ONLY: 'door_only',
    OPENER_ONLY: 'opener_only',
    DOOR_AND_OPENER: 'door_and_opener'
}

export const Answer = {
    YES: 'yes',
    NO: 'no',
    DONT_KNOW: 'dont_know'
}

export const FE_URL = 'https://www.renolition.com';
export const STARTER_PRODUCT = 'price_1Jz7JHHarQrg1J4uu4lTipnW';
export const PRO_PRODUCT = 'price_1Jz7JHHarQrg1J4uu4lTipnW';
export const CALDWELLS_PRODUCT ='price_1Mik4WHarQrg1J4u7xarn0zG';
export const BE_URL = "https://api.renolition.com/apis";

// export const FE_URL = 'http://52.25.179.162';
// export const STARTER_PRODUCT = 'price_1J9WTzHarQrg1J4uHP1YoRl9';
// export const PRO_PRODUCT = 'price_1JyjNYHarQrg1J4uAREQ4uPr';
// export const CALDWELLS_PRODUCT = 'price_1MhKqfHarQrg1J4uHpuyKuar';
// export const BE_URL = "http://34.214.190.204/apis";


export const VENDOR_BASIC_DETAIL_URL = BE_URL + "/vendor_basic_detail";
export const VENDOR_PERMIT_INFO = BE_URL + "/vendor/permit_info";
export const VENDOR_PLACES_URL = BE_URL + "/vendor/places";
export const VERIFIED_REVIEWS_URL = BE_URL + "/vendor/verified_reviews";
export const AUTO_COMPLETE_URL = BE_URL + "/autocomplete";
export const CONTACT_US_URL = BE_URL + "/contact_us";
export const REVIEW_URL = BE_URL + "/review";
export const SIGN_UP_URL = BE_URL + "/sign_up";
export const LOGIN_URL = BE_URL + "/login";
export const LOGOUT_URL = BE_URL + "/logout";
export const PASSWORD_RESET = BE_URL + "/password_reset";
export const REVIEW_ATTACHMENT = BE_URL + "/reviewAttachment";
export const PERMIT_STATS = BE_URL + "/permit_stats";
export const UNSUBSCRIBE = BE_URL + "/unsubscribe";
export const REVIEW_ADMIN_PHOTO = BE_URL + "/admin/review_photo";
export const PERMIT_TAGS_ADMIN = BE_URL + "/admin/permit_tags";
export const INFO_REQUEST_URL = BE_URL + "/info_request";
export const AGENT_CONTACTS_URL = BE_URL + "/agent/contacts";
export const VENDOR_REVIEW_URL =  BE_URL + "/agent/vendor_review";
export const VENDOR_SEARCH_URL =  BE_URL + "/agent/vendor_search";
export const PROPERTY_INFO_URL =  BE_URL + "/agent/property_info";
export const AGENT_AUTO_COMPLETE_URL =  BE_URL + "/agent/autocomplete";
export const AGENT_SEARCH_URL =  BE_URL + "/agent/agent_search";
export const INVITE_INFO_URL =  BE_URL + "/invite_info";
export const AGENT_FRIENDS_URL =  BE_URL + "/agent/agent_friends";
export const AGENT_FAVORITE_VENDOR_URL = BE_URL + '/agent/favorite_vendor';
export const AGENT_FAVORITE_VENDORS_URL = BE_URL + '/agent/favorite_vendors';
export const VENDORS_URL = BE_URL + '/vendors';
export const VETTED_VENDORS_URL = BE_URL + '/vetted_vendors';
export const TRUSTED_VENDORS_URL = BE_URL + '/trusted_vendors';
export const VENDOR_ADD_URL = BE_URL + '/vendor_add';
export const BASIC_CONTACT_DETAILS_URL = BE_URL + '/basic_contact_details';
export const AGENT_CONTACT_VENDORS_URL = BE_URL + '/agent_contact_vendors';
export const GOOGLE_AUTH_URL = BE_URL + '/google_auth';

export const ACCOUNT_URL = BE_URL + "/account";  // full account info, including possible professional details
export const BASIC_ACCOUNT_INFO_URL = BE_URL + "/basic_account_info"; // just basic account details
export const ACCOUNT_PHOTO_URL = BE_URL + "/account/profile_photo";
export const AGENT_CONTACT_FEEDBACK_URL = BE_URL + "/agent_contact_feedback";
export const AGENT_SETTINGS_URL = BE_URL + "/agent/settings";
export const AGENT_CONTACT_ACCESS_URL = BE_URL + "/agent/contact/access";
export const AGENT_CONTACT_ACCESS_EMAIL_URL = BE_URL + '/agent/contact/access/email';
export const AGENT_FRIEND_REQUEST_URL = BE_URL + "/agent/friend_request";
export const CONTACT_LOGIN_URL = BE_URL + "/contact/login";
export const CONTACT_AGENT_TEST_VIEW_URL = BE_URL + '/contact/agent_test_view';
export const FIRST_RUN_URL = BE_URL + '/first_run';
export const VENDOR_PROFILE_DETAIL = BE_URL + '/vendor/profile_detail';
export const VENDOR_PROJECT_PHOTOS = BE_URL + '/vendor/project_photos';
export const PROJECT_INTAKE_PHOTO = BE_URL + '/project_intake/photo';
export const PROJECT_INTAKE = BE_URL + '/project_intake';

export const PLANNING_CODE_SECTIONS = BE_URL + '/code/sections';
export const SF_PLANNING_SEARCH = BE_URL + '/code/sf_planning_search';
export const SF_PLANNING_DETAIL = BE_URL + '/code/sf_planning_detail';
export const SF_PLANNING_INSIGHTS = BE_URL + '/code/sf_planning_insights';
export const PLANNING_PROPERTY_INFO = BE_URL + '/planning/property_info';
export const OAK_PLANNING_SEARCH = BE_URL + '/code/oak_planning_search';
export const OAK_PLANNING_DETAIL = BE_URL + '/code/oak_planning_detail';


export const US_STATES = {
    AL : "Alabama",
    AK : "Alaska",
    AZ : "Arizona",
    AR : "Arkansas",
    CA : "California",
    CO : "Colorado",
    CT : "Connecticut",
    DE : "Delaware",
    DC : "District of Columbia",
    FL : "Florida",
    GA : "Georgia",
    HI : "Hawaii",
    ID : "Idaho",
    IL : "Illinois",
    IN : "Indiana",
    IA : "Iowa",
    KS : "Kansas",
    KY : "Kentucky",
    LA : "Louisiana",
    ME : "Maine",
    MD : "Maryland",
    MA : "Massachusetts",
    MI : "Michigan",
    MN : "Minnesota",
    MS : "Mississippi",
    MO : "Missouri",
    MT : "Montana",
    NE : "Nebraska",
    NV : "Nevada",
    NH : "New Hampshire",
    NJ : "New Jersey",
    NM : "New Mexico",
    NY : "New York",
    NC : "North Carolina",
    ND : "North Dakota",
    OH : "Ohio",
    OK : "Oklahoma",
    OR : "Oregon",
    PA : "Pennsylvania",
    RI : "Rhode Island",
    SC : "South Carolina",
    SD : "South Dakota",
    TN : "Tennessee",
    TX : "Texas",
    UT : "Utah",
    VT : "Vermont",
    VA : "Virginia",
    WA : "Washington",
    WV : "West Virginia",
    WI : "Wisconsin",
    WY : "Wyoming"
}

export const CLASSIFICATIONS_MAP = {
    UNKNOWN: 'Unknown',
    A : 'General Engineering',
    B: 'General Building',
    B2: 'Residential Remodeling',
    C: 'Specialty Contractor',
    C2: 'Insulation and Acoustical',
    C4: 'Boiler, Hot Water Heating and Steam Fitting',
    C5: 'Framing and Rough Carpentry',
    C6: 'Cabinet, Millwork and Finish Carpentry',
    C7: 'Low Voltage Systems',
    C8: 'Concrete',
    C9: 'Drywall',
    C10: 'Electrical',
    C11: 'Elevator',
    C12: 'Earthwork and Paving',
    C13: 'Fencing',
    C15: 'Flooring and Floor Covering',
    C16: 'Fire Protection',
    C17: 'Glazing',
    C20: 'HVAC',
    C21: 'Building Moving/Demolition',
    C22: 'Asbestos Abatement',
    C23: 'Ornamental Metal',
    C27: 'Landscaping',
    C28: 'Lock and Security Equipment',
    C29: 'Masonry',
    C31: 'Construction Zone Traffic Control',
    C32: 'Parking and Highway Improvement',
    C33: 'Painting and Decorating',
    C34: 'Pipeline',
    C35: 'Lathing and Plastering',
    C36: 'Plumbing',
    C38: 'Refrigeration',
    C39: 'Roofing',
    C42: 'Sanitation System',
    C43: 'Sheet Metal',
    C45: 'Sign',
    C46: 'Solar',
    C47: 'General Manufactured Housing',
    C50: 'Reinforcing Steel',
    C51: 'Structural Steel',
    C53: 'Swimming Pool',
    C54: 'Ceramic and Mosaic Tile',
    C55: 'Water Conditioning',
    C57: 'Well Drilling',
    C60: 'Welding',
    C61: 'Limited Specialty',
    ASB: 'Asbestos',
    HAZ: 'Hazardous Substance Removal',
    ARCHITECT:'Architect',
    AGRICULTURAL_ENGINEER: 'Agricultural Engineer',
    CIVIL_ENGINEER: 'Civil Engineer',
    CHEMICAL_ENGINEER: 'Chemical Engineer',
    CONSULTING_ENGINEER: 'Consulting Engineer',
    CORROSION_ENGINEER: 'Corrosion Engineer',
    CONTROL_SYSTEM_ENGINEER: 'Control System Engineer',
    ELECTRICAL_ENGINEER: 'Electrical Engineer',
    ENGINEERING_GEOLOGIST: 'Engineering Geologist',
    ENGINEER_IN_TRAINING: 'Engineer-in-training',
    FIRE_PROTECTION_ENGINEER: 'Fire Protection Engineer',
    GEOTECHNICAL_ENGINEER: 'Geotechnical Engineer',
    PROFESSIONAL_GEOLOGIST: 'Professional Geologist',
    GEOLOGIST_IN_TRAINING: 'Geologist-in-training',
    PROFESSIONAL_GEOPHYSICIST: 'Professional Geophysicist',
    HYDROGEOLOGIST: 'Hydrogeologist',
    INDUSTRIAL_ENGINEER: 'Industrial Engineer',
    LAND_SURVEYOR: 'Land Surveyor',
    LAND_SURVEYOR_IN_TRAINING: 'Land Surveyor-in-training',
    MECHANICAL_ENGINEER: 'Mechanical Engineer',
    MANUFACTURING_ENGINEER: 'Manufacturing Engineer',
    METALLURGICAL_ENGINEER: 'Metallurgical Engineer',
    NUCLEAR_ENGINEER: 'Nuclear Engineer',
    PETROLEUM_ENGINEER: 'Petroleum Engineer',
    PHOTOGRAMMETRIC_SURVEYOR: 'Photogrammetric Surveyor',
    QUALITY_ENGINEER: 'Quality Engineer',
    STRUCTURAL_ENGINEER: 'Structural Engineer',
    SAFETY_ENGINEERS: 'Safety Engineers',
    TRAFFIC_ENGINEERS: 'Traffic Engineers',
}

export const VENDOR_CATEGORIES = {
    GENERAL_BUILDING: 'General building',
    ENGINEER: 'Engineer',
    ARCHITECT: 'Architect',
    ELECTRICAL: 'Electrical',
    FIRE: 'Fire Protection',
    HVAC: 'HVAC',
    LANDSCAPING: 'Landscaping',
    PLUMBING: 'Plumbing',
    ROOFING: 'Roofing',
    SOLAR: 'Solar',
    FLOORING: 'Flooring',
    PAINTING: 'Painting',
    HANDYPERSON: 'Handy person',
    MORTGAGE_LENDER: 'Mortgage lender',
    LAWYER: 'Lawyer',
    OTHER: 'Other',
}


export const PLUMBING_TAG_MAP_TOOL_TIP = { 
    bathroom_remodel: 'Addition and remodeling of bathrooms, including fixtures, drains, sewer, etc',
    kitchen_remodel: 'Addition and remodeling of kitchens, including sinks, drains, appliance hookups, etc.',
    laundry: 'Plumbing work related to laundries.',
    sewer: 'All sewer and underground related work, including house traps and sewer lateral work',
    hvac: 'Installation / modification of HVAC systems, other than furnaces and radiant heat, such as air conditioning and other air flow systems.',
    general: 'General plumbing work, such as waterline work.',
    
    water_heater: 'Installation / modification of water heaters',
    boiler: 'Installation / modification of boilers',
    ducting: 'Installation / modification of ducting',
    fireplace: 'Installation / modification of fireplaces',
    fire_sprinkler: 'Plumbing work related to fire protection systems such as fire sprinklers.',
    commercial: 'Plumbing work in a commercial unit, including restaurants.',
    gas_line: 'Work that is isolated to the gasline, such as extending the line to a particular appliance.',
    furnace: 'Installation or modification of a furnace.',
    radiant_heat: 'Installation or modification of a radiant heat system.',
    shower: 'Installation of showers. This type of permit is typically in addition to a bathroom remodeling permit.',
    back_flow: 'Installation and repair of backflow preventors',

    // currently not returned from backend
    sewer_lateral: 'Installation/repair of the house sewer lateral',
    house_trap: 'Installation/repair of the house trap sewer section.',
    restaurant: 'Plumbing work in a restaurant environment.',
    units_small: 'Plumbing work in a 1-2 Unit Buildings',
    units_med: 'Plumbing work in a 3-6 Unit Buildings',
    units_large: 'Plumbing work in a 7+ Unit Buildings',
}

export const BUILDING_TAG_MAP = { 
    foundation: "Foundations",
    bathroom: "Bathrooms",
    stairs: "Stairs",
    addition: "Liveable Space",
    shoring: "Shoring",
    structural: "Framing/Steel",
    door_and_window: "Doors/Windows",
    dryrot: "Dryrot/Pest",
    facade: "Facade/Siding",
    deck: "Decks/Patios",
    kitchen: "Kitchens",
    laundry: "Laundries",
    roof: "Roofing",
    fire: "Fire Protection",
    hvac: "HVAC",
    adu: "ADUs/New Units",
    demolition: "Demolition",
    fence_and_landscaping: "Fences/Landscaping",
    remodel: "Other Remodel",
    living_space: "Living Apace",
}

export const ELECTRICAL_TAG_MAP_TOOL_TIP  = { 
    bathroom: "Remodeling and addition of bathrooms.",
    car_charger: 'Installation of car/ev car charging stations.',
    fire: "Installation, repair, alteration of fire protection, including alarms",
    kitchen:  "Remodeling and addition of kitchens.",
    light_switch_outlet: 'Installation or replacement of lights, switches, outlets, recepticales, etc.',
    panel: 'Installation or replacement of panels / subpanels.',
    rewiring: 'Rewiring of the all or part of the structure, usually when the existing wiring is old / not up to current codes.',
    service_upgrade: 'Service upgrade, usually from a lower amperage to a higher one.',
    solar: 'Solar panels, or related to solar.',
}

export const BUILDING_TAG_MAP_TOOL_TIP = { 
    foundation: "Repair, reinforcement and construction of new foundations, permation grouting, slabs.",
    bathroom: "Remodeling and addition of bathrooms.",
    stairs: "Construction or repair of interior and exterior stairs. Exterior stairs can include main entry stairs or deck/patio stairs.",
    addition: "Any type of addition or extension to the existing structure, including horizontal or vertical extensions, or conversions of a section of the building to habitable space.",
    shoring: "Temporary shoring installation.",
    structural: "Repair and construction that is structural in nature, such as removal of load-bearing walls, retaining walls, adding steal beams, structural posts, etc.",
    door_and_window: "Intallation, repair, or alteration of windows, and exterior/interior doors.",
    dryrot: "Repair/remediation of dryrot and other pest related damages, including termites.",
    facade: "Repair, alteration or construction of the outside/facade of the building. This includes installing/reparing siding, waterproofing material, stucco, etc.",
    deck: "Construction, alteration or repair related to decks and patios.",
    kitchen: "Remodeling and addition of kitchens.",
    laundry: "Remodeling and addition of laundry areas and/or rooms.",
    roof: "Any repair or installation of a new roof.",
    fire: "Installation, repair, alteration of fire protection, including alarms, sprinklers etc.",
    hvac: "Installation and repair of HVAC systems, including forced air, radiant heat, air conditioning.",
    adu: "Additon of ADUs or other secondary units.",
    demolition: "Demolition of parts/all of the building. This work is often included in other larger jobs, but is also performed by itself.",
    fence_and_landscaping: "Repair and installation of new fences. Any other kind of landscaping.",
    remodel: "General remodeling of parts/all of the interior, including living rooms, bedrooms etc.",
    living_space: "Living space such as living rooms, dining rooms, bedrooms, and family areas."
}

export const COMBINED_TAG_MAP = { 
    e_bathroom: 'Bathroom wiring',
    e_car_charger: 'Car/EV charging',
    e_fire: 'Fire safety wiring',
    e_kitchen: 'Kitchen wiring',
    e_light_switch_outlet: 'Lights, switches, outlets',
    e_panel: 'Panels/subpanels',
    e_rewiring: 'General rewiring',
    e_service_upgrade: 'Service upgrade',
    e_solar: 'Solar',
    e_other: 'General electrical',

    b_bathroom: 'Bathroom remodel',
    b_kitchen: 'Kitchen remodel',
    b_laundry: "Laundry remodel",

    b_foundation: "Foundations",
    b_stairs: "Stairs",
    b_addition: "Adding space",
    b_fire: "Fire safety",
    b_shoring: "Shoring",
    b_structural: "Framing/steel",
    b_door_and_window: "Windows & doors",
    b_dryrot: "Dryrot/pest",
    b_facade: "Facade/siding",
    b_deck: "Decks/patios",
    b_roof: "Roofing",
    b_hvac: "HVAC",
    b_adu: "ADUs/new units",
    b_demolition: "Demolition",
    b_fence_and_landscaping: "Fences/landscaping",
    b_remodel: "Other remodel",
    b_living_space: "Living space",
    
    p_bathroom_remodel: 'Bathroom',
    p_kitchen_remodel: 'Kitchen ',
    p_laundry: 'Laundry ',
    p_sewer: 'Sewer/sewer lateral',
    p_hvac: 'HVAC',
    p_general: 'General plumbing',
    p_water_heater: 'Water heaters',
    p_boiler: 'Boilers',
    p_ducting: 'Duct work',
    p_fireplace: 'Fireplace ',
    p_fire_sprinkler: 'Fire safety plumbing',
    p_commercial: 'Commercial ',
    p_gas_line: 'Gas line work',
    p_furnace: 'Furnaces',
    p_radiant_heat: 'Radiant heat',
    p_shower: 'Showers',
    p_back_flow: 'Backflow prevention',
}

export const COMBINED_TAG_MAP_TOOL_TIP = { 
    b_foundation: "Repair, reinforcement and construction of new foundations, permation grouting, slabs.",
    b_bathroom: "Remodeling and addition of bathrooms.",
    b_stairs: "Construction or repair of interior and exterior stairs. Exterior stairs can include main entry stairs or deck/patio stairs.",
    b_addition: "Any type of addition or extension to the existing structure, including horizontal or vertical extensions, or conversions of a section of the building to habitable space.",
    b_shoring: "Temporary shoring installation.",
    b_structural: "Repair and construction that is structural in nature, such as removal of load-bearing walls, retaining walls, adding steal beams, structural posts, etc.",
    b_door_and_window: "Intallation, repair, or alteration of windows, and exterior/interior doors.",
    b_dryrot: "Repair/remediation of dryrot and other pest related damages, including termites.",
    b_facade: "Repair, alteration or construction of the outside/facade of the building. This includes installing/reparing siding, waterproofing material, stucco, etc.",
    b_deck: "Construction, alteration or repair related to decks and patios.",
    b_kitchen: "Remodeling and addition of kitchens.",
    b_roof: "Any repair or installation of a new roof.",
    b_fire: "Installation, repair, alteration of fire protection, including alarms, sprinklers etc.",
    b_hvac: "Installation and repair of HVAC systems, including forced air, radiant heat, air conditioning.",
    b_adu: "Additon of ADUs or other secondary units.",
    b_demolition: "Demolition of parts/all of the building. This work is often included in other larger jobs, but is also performed by itself.",
    b_fence_and_landscaping: "Repair and installation of new fences. Any other kind of landscaping.",
    b_remodel: "General remodeling of parts/all of the interior, including living rooms, bedrooms etc.",
    b_living_space: "Living space such as living rooms, dining rooms, bedrooms, and family areas.",
    b_car_charger: 'Installation of car/ev car charging stations.',
    b_light_switch_outlet: 'Installation or replacement of lights, switches, outlets, recepticales, etc.',
    b_panel: 'Installation or replacement of panels / subpanels.',
    b_rewiring: 'Rewiring of the all or part of the structure, usually when the existing wiring is old / not up to current codes.',
    b_service_upgrade: 'Service upgrade, usually from a lower amperage to a higher one.',
    b_solar: 'Solar panels, or related to solar.',

    p_bathroom_remodel: 'Addition and remodeling of bathrooms, including fixtures, drains, sewer, etc',
    p_kitchen_remodel: 'Addition and remodeling of kitchens, including sinks, drains, appliance hookups, etc.',
    p_laundry: 'Work related to laundries.',
    p_sewer: 'All sewer and underground related work, including house traps and sewer lateral work',
    p_general: 'General plumbing work, such as waterline work.',
    
    p_water_heater: 'Installation / modification of water heaters',
    p_boiler: 'Installation / modification of boilers',
    p_ducting: 'Installation / modification of ducting',
    p_fireplace: 'Installation / modification of fireplaces',
    p_fire_sprinkler: 'Plumbing work related to fire protection systems such as fire sprinklers.',
    p_commercial: 'Plumbing work in a commercial unit, including restaurants.',
    p_gas_line: 'Work that is isolated to the gasline, such as extending the line to a particular appliance.',
    p_furnace: 'Installation or modification of a furnace.',
    p_radiant_heat: 'Installation or modification of a radiant heat system.',
    p_shower: 'Installation of showers. This type of permit is typically in addition to a bathroom remodeling permit.',
    p_back_flow: 'Installation and repair of backflow preventors',

    // currently not returned from backend
    p_sewer_lateral: 'Installation/repair of the house sewer lateral.',
    p_house_trap: 'Installation/repair of the house trap sewer section.',
    p_restaurant: 'Plumbing work in a restaurant environment.',
    p_units_small: 'Plumbing work in a 1-2 Unit Buildings.',
    p_units_med: 'Plumbing work in a 3-6 Unit Building.s',
    p_units_large: 'Plumbing work in a 7+ Unit Buildings.',

    e_bathroom: 'Electrical work/rewiring of bathrooms.',
    e_car_charger: 'Car/EV charging station installation/maintenance.',
    e_fire: 'Fire alarm and protection electrical work.',
    e_kitchen: 'Electrical work/rewiring of kitchen.s',
    e_light_switch_outlet: 'Installation, repair, replacement of lights, switches, outlets.',
    e_panel: 'Repair, replacement or isntallation of panels and subpanels.',
    e_rewiring: 'General rewiring of the proeprty, or parts of the property.',
    e_service_upgrade: 'Service upgrade.',
    e_solar: 'Solar related electrical work.',
    e_other: 'General electrical work that requires a permit.',
}

export const ValidCodes = [
    'BFMT73', // start 1
    'B78924', // start 3
    'BFM87S', //start 2
    'BFM89E', //end 1
    'BMT342', //start 4
    'BM3324', // end 2
    'BT7942', // filed 1
]

export const PropertyClassMap = {
    COS: 'Single Family Residential - Coop Units Segregated',
    D: 'Single Family Residential - Dwelling',
    DBM: 'Single Family Residential - Dwelling BMR',
    LZ: 'Single Family Residential - Live/Work Condominium',
    LZBM: 'Single Family Residential - Live/Work Condominium BMR',
    TH: 'Single Family Residential - Town House',
    THBM: 'Single Family Residential - Town House BMR',
    Z: 'Single Family Residential - Condominium',
    ZBM: 'Single Family Residential - Condominium BMR',
    TS: 'Commercial Misc - Timeshare',
    PD: 'Commercial Misc - PUD (Planned Unit Development)',
    TSF: 'Commercial Misc - Time Share Fractional',
    TSU: 'Commercial Misc - Time Share Unsegregated',
    UWL: 'Miscellaneous/Mixed-Use - Under Water Lot',
    V: 'Miscellaneous/Mixed-Use - Vacant Lot',
    VR: 'Miscellaneous/Mixed-Use - Vacant Lot - Restrictions',
    A: 'Multi-Family Residential - Apartment',
    CO: 'Multi-Family Residential - Coop Units Unsegregated',
    DA: 'Multi-Family Residential - Dwellings - Apartments',
    DD: 'Multi-Family Residential - 2 Dwellings on One Parcel',
    DF: 'Multi-Family Residential - 1 Flat & Dwelling-1 Parcel',
    F: 'Multi-Family Residential - Flats & Duplex',
    FA: 'Multi-Family Residential - 1 Flat & 1 Apt Bldg-1 Parcel',
    F2: 'Multi-Family Residential - Flat & Store',
    OA: 'Multi-Family Residential - Office and Apartments',
    TIA: 'Multi-Family Residential - TI Apartment',
    TIF: 'Multi-Family Residential - TI Flats & Duplex',
    XV: 'Multi-Family Residential - Single Struct on Multi Lot(D & Fs only)',
    H: 'Commercial Hotel - Hotel',
    HC: 'Commercial Hotel - Hotel Commercial (H2w/Com)',
    H1: 'Commercial Hotel - Hotel',
    H2: 'Commercial Hotel - Hotels - Other',
    M: 'Commercial Hotel - Motels',
    RH: 'Commercial Hotel - Residential Hotel & SRO',
    RH1: 'Commercial Hotel - Retail & Hotel',
    AC: 'Commercial Misc - Apartmnt & Commercial Store',
    E: 'Commercial Misc - Schools',
    G: 'Commercial Misc - Garages (Commercial)',
    GC: 'Commercial Misc - Golf Course',
    GZ: 'Commercial Misc - Garage Condominium',
    MIX: 'Commercial Misc - Mixed use',
    N1: 'Commercial Misc - Hospitals',
    N2: 'Commercial Misc - Convalescent/Nursing Homes',
    PL: 'Commercial Misc - Parking Lot',
    PZ: 'Commercial Misc - Parking Stall Condominium',
    S: 'Commercial Misc - Gas Station',
    T: 'Commercial Misc - Theatres',
    U: 'Commercial Misc - Clubs,Lodges,Fraternal Organizations',
    W: 'Commercial Misc - Churches,Convents,Rectories',
    O: 'Commercial Office - Office',
    OAH: 'Commercial Office - Office - High Class A',
    OAT: 'Commercial Office - Office - "Trophy" Class A',
    OBH: 'Commercial Office - Office - High Class B',
    OBM: 'Commercial Office - Office - Middle Class B',
    OCH: 'Commercial Office - Office - High Class C',
    OCL: 'Commercial Office - Office - Low Class C',
    OCM: 'Commercial Office - Office - Middle Class C',
    OMD: 'Commercial Office - Medical- dental Office Building',
    OZ: 'Commercial Office - Office - Condominium',
    B: 'Commercial Retail - Bank',
    BZ: 'Commercial Retail - Bank Condominium',
    C: 'Commercial Retail - Commercial Stores',
    CD: 'Commercial Retail - Commercial Department Stores',
    CM: 'Commercial Retail - Commercial/Mixed use',
    CZ: 'Commercial Retail - Commercial Store Condo',
    C1: 'Commercial Retail - Shopping Center',
    EC: 'Commercial Retail - Entertainment Complex',
    OC: 'Commercial Retail - Office with Major Retail Units',
    CP: 'Government - City Property',
    P: 'Government - Public Buildings (Govt)',
    RDAP: 'Government - Redevelopment Agency Property',
    SP: 'Government - State of California Property',
    USP: 'Government - U.S. Government Property',
    Y: 'Government - Port Commission Property',
    I: 'Industrial - Industrial',
    IDC: 'Industrial - Industrial Data Center',
    IW: 'Industrial - Industial Warehouse',
    IX: 'Industrial - Industrial Mixed/Other Use',
    IZ: 'Industrial - Industrial Condominium',
    MB: 'Miscellaneous/Mixed-Use - Mission Bay',
    X: 'Miscellaneous/Mixed-Use - Misc',
    F5: 'Multi-Family Residential - Flats 5 to 14 units ',
    VPUB: 'Miscellaneous/Mixed-Use - Vacant Lot Public Use',
    DCON: 'Multi-Family Residential - Legal Multi-Family Con to SFR ',
    VCI: 'Miscellaneous/Mixed-Use - Vacant Lot Comm and Ind ',
    TIC: 'Multi-Family Residential - TIC Bldg 4 units or less ',
    VCIX: 'Miscellaneous/Mixed-Use - Vacant Lot Comm and Ind w/ Restriction',
    FS: 'Multi-Family Residential - Flat & Store 4 units or less',
    TIC5: 'Multi-Family Residential - TIC Bldg 5 to 14 units',
    OZEU: 'Single Family Residential - Office Condo Economic Unit ',
    ZEU: 'Single Family Residential - Condominium Economic Unit ',
    OA15: 'Multi-Family Residential - Office and Apartments 15 units +',
    GCU : 'Commercial Misc - Golf Course',
    FS15: 'Multi-Family Residential - Flat & Store 15 units +',
    F15: 'Multi-Family Residential - Flats 15 units +',
    VA15: 'Miscellaneous/Mixed-Use - Vacant land- residential 15+ units ',
    OAL  : 'Commercial Office - Office - Low Class A',
    A5: 'Multi-Family Residential - Apartment 5 to 14 Units',
    FA5: 'Multi-Family Residential - 1 Flat & 1 Apt - 1 Parcel 5 to 14 units ',
    DA15: 'Multi-Family Residential - Dwellings - Apt 15 units or more ',
    TI15: 'Multi-Family Residential - TIC Bldg 15 units + ',
    FS5: 'Multi-Family Residential - Flat & Store 5 to 14 units',
    O35: 'Commercial Office - Office Portion Leased of 35 or More ',
    DA5: 'Multi-Family Residential - Dwellings - Apt 5 to 14 units ',
    UCP: 'Government - University of California Property',
    DD5: 'Multi-Family Residential - 2 Dwellings on 1 Parcel 5 to 14 units ',
    A15: 'Multi-Family Residential - Apartment 15 Units or more ',
    VRX: 'Miscellaneous/Mixed-Use - Vacant Lot Residential w/ Restriction ',
    OA5: 'Multi-Family Residential - Office and Apartments 5 to 14 units',
}

