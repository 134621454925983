
export const SfPlanningRecordType = {
    AHB: 'Affordable Housing Bonus (AHB)',
    APL: 'Appeals (APL)',
    // CND: 'Condo-REF (CND)',
    COA: 'Certificate of Appropriateness (COA)',
    // CRV: 'Commission Review (CRV)',
    CTZ: 'Coastal Zone Permit (CTZ)',
    CUA: 'Conditional Use Authorization (CUA)',
    // CWP: 'Citywide Planning (CWP)',
    // DES: 'Designations (DES)',
    // DNX: 'Downtown Exception-309 (DNX)',
    DRM: 'Discretionary Review - Mandatory (DRM)',
    DRP: 'Discretionary Review - Public Initiated (DRP)',
    // DVA: 'Development Agreements-LEG (DVA)',
    ENF: 'Enforcement (ENF)',
    ENV: 'Environmental (ENV)',
    // ENX: 'Eastern Neighborhoods Exception-329 (ENX)',
    // FED: 'Federal Section 106 (FED)',
    // GPA: 'General Plan Amendment-LEG (GPA)',
    // GPR: 'General Plan Referral (GPR)',
    HRR: 'Historic Resource Review (HRR)',
    // IMP: 'Institutional Master Plan (IMP)',
    // LLA: 'Lot Line Adjustments-REF (LLA)',
    // MAP: 'Zoning Map Amendment-LEG (MAP)',
    MCM: 'Monitoring Conditions and Mitigation Measures (MCM)',
    // OFA: 'Office Allocation-321 (OFA)',
    // PCA: 'Plan Code Amendment-LEG (PCA)',
    // PPA: 'Preliminary Project Assessment (PPA)',
    // PPS: 'Preliminary Project Submittal (PPS)',
    // PRJ: 'Project Profile (PRJ)',
    PRV: 'Project Review Meetings (PRV)',
    // PTA: 'Permit to Alter Major/Minor (PTA)',
    SHD: 'Shadow Study (SHD)',
    SUB: 'Subdivision-REF (SUB)',
    TDE: 'Statement of Eligibility-TDR (TDE)',
    TDM: 'Transportation Demand Management (TDM)',
    TDT: 'Certificate of Transfer-TDR (TDT)',
    VAR: 'Variance (VAR)',
    ZAD: 'Zoning Administrator Determination Letter (ZAD)',
    ZAV: 'Zoning Administrator Verification Letter (ZAV)',
}

export const SfPlanningFileType = {
    'Application': 'Application',
    'Staff Reports': 'Staff Reports',
    'Correspondence': 'Correspondence',
    'Notification': 'Notification',
    'Plans': 'Plans',
    'Shadow Study': 'Shadow Study',
    'Zoning Verification Letters': 'Zoning Verification Letters',
    // 'Coastal Permit Authorizations': 'Coastal Permit Authorizations',   
    // 'Document': 'Document',
    // 'DR Action Memos': 'DR Action Memos',
    // 'Executive Summary': 'Executive Summary',
    // 'Exhibits Report': 'Exhibits Report',
    // 'General Plan Referral Findings': 'General Plan Referral Findings',
    // 'Graphics': 'Graphics',
    // 'HPC Motions and Resolutions': 'HPC Motions and Resolutions',
    // 'Legislation and Ordinances': 'Legislation and Ordinances',
    // 'Map': 'Map',
    // 'Minor Permit to Alter': 'Minor Permit to Alter',
    // 'Miscellaneous Permit Responses': 'Miscellaneous Permit Responses',
    // 'Notices of Violation and Penalty': 'Notices of Violation and Penalty',
    // 'Other document': 'Other document',
    // 'Other': 'Other',
    // 'Permit to Alter': 'Permit to Alter',
    // 'PPA Letters': 'PPA Letters',
    // 'Preservation Review': 'Preservation Review',
    // 'Public Info Requests': 'Public Info Requests',    
    // 'Working Document': 'Working Document',
    // 'ZA Action Memos': 'ZA Action Memos',
    // 'ZA Letters Other': 'ZA Letters Other',
}

// export const SfPlanningFileType = {
//     'Application': 'Application',
//     'Board of Appeals Decisions': 'Board of Appeals Decisions',
//     'Coastal Permit Authorizations': 'Coastal Permit Authorizations',
//     'Commission Submittal': 'Commission Submittal',
//     'Correspondence': 'Correspondence',
//     'CPC Motions and Resolutions': 'CPC Motions and Resolutions',
//     'Document': 'Document',
//     'DR Action Memos': 'DR Action Memos',
//     'Environmental Analysis': 'Environmental Analysis',
//     'Environmental Monitoring': 'Environmental Monitoring',
//     'Executive Summary': 'Executive Summary',
//     'Exhibits Report': 'Exhibits Report',
//     'General Plan Referral Findings': 'General Plan Referral Findings',
//     'Graphics': 'Graphics',
//     'HPC Motions and Resolutions': 'HPC Motions and Resolutions',
//     'Legislation and Ordinances': 'Legislation and Ordinances',
//     'Letters of Determination': 'Letters of Determination',
//     'Map': 'Map',
//     'Minor Permit to Alter': 'Minor Permit to Alter',
//     'Miscellaneous Permit Responses': 'Miscellaneous Permit Responses',
//     'Notice of Special Restrictions': 'Notice of Special Restrictions',
//     'Notices of Violation and Penalty': 'Notices of Violation and Penalty',
//     'Notification': 'Notification',
//     'Other document': 'Other document',
//     'Other': 'Other',
//     'Permit to Alter': 'Permit to Alter',
//     'Photos': 'Photos',
//     'Planning Approval Letter': 'Planning Approval Letter',
//     'Plans': 'Plans',
//     'PPA Letters': 'PPA Letters',
//     'Preservation Review': 'Preservation Review',
//     'Public Info Requests': 'Public Info Requests',
//     'Shadow Study': 'Shadow Study',
//     'Staff Reports': 'Staff Reports',
//     'Technical Reports': 'Technical Reports',
//     'Variance Decision Letters': 'Variance Decision Letters',
//     'Working Document': 'Working Document',
//     'ZA Action Memos': 'ZA Action Memos',
//     'ZA Letters Other': 'ZA Letters Other',
//     'Zoning Verification Letters': 'Zoning Verification Letters',
// }

export const SfUseDistrict = {
    'C-2': 'C-2',
    'C-3-G': 'C-3-G',
    'C-3-O': 'C-3-O',
    'C-3-O(SD)': 'C-3-O(SD)',
    'C-3-R': 'C-3-R',
    'C-3-S': 'C-3-S',
    'CCB': 'CCB',
    'CMUO': 'CMUO',
    'CRNC': 'CRNC',
    'CVR': 'CVR',
    'HP-RA': 'HP-RA',
    'M-2': 'M-2',
    'MB-RA': 'MB-RA',
    'MUG': 'MUG',
    'MUO': 'MUO',
    'MUR': 'MUR',
    'NC-1': 'NC-1',
    'NC-2': 'NC-2',
    'NC-3': 'NC-3',
    'NC-S': 'NC-S',
    'NCD-24TH-NOE-VALLEY': 'NCD-24TH-NOE-VALLEY',
    'NCD-CASTRO': 'NCD-CASTRO',
    'NCD-CORTLAND AVENUE': 'NCD-CORTLAND AVENUE',
    'NCD-EXCELSIOR': 'NCD-EXCELSIOR',
    'NCD-GEARY BOULEVARD': 'NCD-GEARY BOULEVARD',
    'NCD-HAIGHT': 'NCD-HAIGHT',
    'NCD-INNER BALBOA STREET': 'NCD-INNER BALBOA STREET',
    'NCD-INNER CLEMENT': 'NCD-INNER CLEMENT',
    'NCD-INNER SUNSET': 'NCD-INNER SUNSET',
    'NCD-INNER TARAVAL STREET': 'NCD-INNER TARAVAL STREET',
    'NCD-IRVING': 'NCD-IRVING',
    'NCD-JAPANTOWN': 'NCD-JAPANTOWN',
    'NCD-LAKESIDE VILLAGE': 'NCD-LAKESIDE VILLAGE',
    'NCD-LOWER POLK STREET': 'NCD-LOWER POLK STREET',
    'NCD-MISSION BERNAL': 'NCD-MISSION BERNAL',
    'NCD-NORIEGA': 'NCD-NORIEGA',
    'NCD-NORTH BEACH': 'NCD-NORTH BEACH',
    'NCD-OUTER CLEMENT': 'NCD-OUTER CLEMENT',
    'NCD-POLK': 'NCD-POLK',
    'NCD-SAN BRUNO AVENUE': 'NCD-SAN BRUNO AVENUE',
    'NCD-TARAVAL': 'NCD-TARAVAL',
    'NCD-UNION': 'NCD-UNION',
    'NCD-WEST PORTAL': 'NCD-WEST PORTAL',
    'NCT-2': 'NCT-2',
    'NCT-3': 'NCT-3',
    'NCT-DIVISADERO': 'NCT-DIVISADERO',
    'NCT-FILLMORE': 'NCT-FILLMORE',
    'NCT-GLEN PARK': 'NCT-GLEN PARK',
    'NCT-HAYES': 'NCT-HAYES',
    'NCT-MISSION': 'NCT-MISSION',
    'NCT-OCEAN': 'NCT-OCEAN',
    'NCT-UPPER MARKET': 'NCT-UPPER MARKET',
    'NCT-VALENCIA': 'NCT-VALENCIA',
    'P': 'P',
    'PDR-1-B': 'PDR-1-B',
    'PDR-1-D': 'PDR-1-D',
    'PDR-1-G': 'PDR-1-G',
    'PDR-2': 'PDR-2',
    'RC-3': 'RC-3',
    'RC-4': 'RC-4',
    'RCD': 'RCD',
    'RED': 'RED',
    'RH DTR': 'RH DTR',
    'RH-1': 'RH-1',
    'RH-1(D)': 'RH-1(D)',
    'RH-1(S)': 'RH-1(S)',
    'RH-2': 'RH-2',
    'RH-3': 'RH-3',
    'RM-1': 'RM-1',
    'RM-2': 'RM-2',
    'RM-3': 'RM-3',
    'RM-4': 'RM-4',
    'RTO-M': 'RTO-M',
    'RTO': 'RTO',
    'SALI': 'SALI',
    'UMU': 'UMU',
    'WMUG': 'WMUG',
    'WMUO': 'WMUO',
}

export const SfHeightDistrict = {
    '105-D': '105-D',
    '105-E': '105-E',
    '105-X': '105-X',
    '110-X': '110-X',
    '120-R-2': '120-R-2',
    '120-X': '120-X',
    '120/400-R-2 // 140/520-R-2': '120/400-R-2 // 140/520-R-2',
    '120/400-R-2': '120/400-R-2',
    '130-CS-200-CS': '130-CS-200-CS',
    '130-CS': '130-CS',
    '130-E': '130-E',
    '150-S': '150-S',
    '160-F': '160-F',
    '160-M': '160-M',
    '160-S': '160-S',
    '200-CS': '200-CS',
    '200-S': '200-S',
    '25-X': '25-X',
    '250-S': '250-S',
    '26-40-X': '26-40-X',
    '26-X': '26-X',
    '28-X': '28-X',
    '30-X': '30-X',
    '300-S': '300-S',
    '320-I': '320-I',
    '340-I': '340-I',
    '350-S': '350-S',
    '40-X': '40-X',
    '40/55-X': '40/55-X',
    '400-S': '400-S',
    '400-W': '400-W',
    '45-X': '45-X',
    '450-S': '450-S',
    '48-X': '48-X',
    '50-N': '50-N',
    '50-X': '50-X',
    '500-I': '500-I',
    '55-X': '55-X',
    '550-S': '550-S',
    '58-X': '58-X',
    '60/65-X': '60/65-X',
    '65-85-N': '65-85-N',
    '65-A': '65-A',
    '65-B': '65-B',
    '65-J': '65-J',
    '65-N': '65-N',
    '65-X': '65-X',
    '68-X': '68-X',
    '75-X': '75-X',
    '80-130-F': '80-130-F',
    '80-A': '80-A',
    '80-B': '80-B',
    '80-D': '80-D',
    '80-E': '80-E',
    '80-T-130-T': '80-T-130-T',
    '80-T': '80-T',
    '80-X': '80-X',
    '84-E': '84-E',
    '84-X': '84-X',
    '85-X': '85-X',
    '90-X': '90-X',
    'HP-RA': 'HP-RA',
    'MB-RA': 'MB-RA',
    'OS': 'OS',
}

export const SfSpecialUseDistrict = {
    '3rd St Alcohol Restrict': '3rd St Alcohol Restrict',
    '901 Bush Street SUD': '901 Bush Street SUD',
    'Art & Design Education': 'Art & Design Education',
    'Bayshore Blvd Home Improvement': 'Bayshore Blvd Home Improvement',
    'Bayshore Hester NC': 'Bayshore Hester NC',
    'Bayview Industrial Triangle Cannabis RUD': 'Bayview Industrial Triangle Cannabis RUD',
    'Bernal Heights': 'Bernal Heights',
    'Calle 24 SUD': 'Calle 24 SUD',
    'Central Neighborhoods Large Residence': 'Central Neighborhoods Large Residence',
    'Central SoMa': 'Central SoMa',
    'Chestnut St Fin Svcs Restrict': 'Chestnut St Fin Svcs Restrict',
    'Corona Heights Large Residence': 'Corona Heights Large Residence',
    'Design & Development': 'Design & Development',
    'Dolores Heights': 'Dolores Heights',
    'ELIMINATED: Industrial Protection Zone': 'ELIMINATED: Industrial Protection Zone',
    'Family and Senior Housing Opportunity SUD': 'Family and Senior Housing Opportunity SUD',
    'Folsom & Main Res Comm': 'Folsom & Main Res Comm',
    'Fringe Financial Services RUD': 'Fringe Financial Services RUD',
    'Geary Blvd Formula Retail Pet Supply Store and Formula Retail Eating and Drinking Subdistrict': 'Geary Blvd Formula Retail Pet Supply Store and Formula Retail Eating and Drinking Subdistrict',
    'Group Housing Special Use District': 'Group Housing Special Use District',
    'Haight Street Alcohol Restricted Use': 'Haight Street Alcohol Restricted Use',
    'India Basin Industrial Park': 'India Basin Industrial Park',
    'Innovative Industries': 'Innovative Industries',
    'Jackson Square': 'Jackson Square',
    'Japantown': 'Japantown',
    'Lakeshore Plaza NC': 'Lakeshore Plaza NC',
    'Lower Polk Street Alcohol Restricted Use': 'Lower Polk Street Alcohol Restricted Use',
    'Mission Alcohol Restrict': 'Mission Alcohol Restrict',
    'Mission Street Formula Retail Restaurant Subdistrict': 'Mission Street Formula Retail Restaurant Subdistrict',
    'North Beach Limited Financial': 'North Beach Limited Financial',
    'North Beach SUD': 'North Beach SUD',
    'North of Market Residential 1': 'North of Market Residential 1',
    'North of Market Residential 2': 'North of Market Residential 2',
    'Oceanview Large Residence': 'Oceanview Large Residence',
    'OLD: Ocean Ave Fast Food': 'OLD: Ocean Ave Fast Food',
    'Priority Equity Geographies SUD': 'Priority Equity Geographies SUD',
    'Taraval Street Restaurant Subdistrict': 'Taraval Street Restaurant Subdistrict',
    'Telegraph Hill-NB Residential': 'Telegraph Hill-NB Residential',
    'Third Street': 'Third Street',
    'Transbay C3': 'Transbay C3',
    'Transit Center C-3-O(SD) Commercial': 'Transit Center C-3-O(SD) Commercial',
    'Van Ness and Market Residential': 'Van Ness and Market Residential',
    'Van Ness Automotive': 'Van Ness Automotive',
    'Van Ness': 'Van Ness',
    'Washington-Broadway SUD': 'Washington-Broadway SUD',
    'Waterfront 2': 'Waterfront 2',
    'Waterfront 3': 'Waterfront 3',
    'Waterfront 4': 'Waterfront 4',
    'Western SoMa': 'Western SoMa',
    'Within 1/4 Mile of an Existing Fringe Financial Service': 'Within 1/4 Mile of an Existing Fringe Financial Service',
    'Within 1/4 Mile of the Fringe Financial Services RUD': 'Within 1/4 Mile of the Fringe Financial Services RUD',
    'Youth and Family Zone': 'Youth and Family Zone',
}

export const DistanceMile = [
    {value: "0", display: "Any distance"},
    {value: "0.125", display: "Within 1/8 mile"},
    {value: "0.25", display: "Within 1/4 mile"},
    {value: "0.5", display: "Within 1/2 mile"},
    {value: "0.75", display: "Within 3/4 mile"},
    {value: "1.0", display: "Within 1 mile"},
    {value: "1.25", display: "Within 1 1/4 miles"},
    {value: "1.5", display: "Within 1 1/2 miles"},
    {value: "1.75", display: "Within 1 3/4 miles"},
    {value: "2", display: "Within 2 miles"},
    {value: "2.5", display: "Within 2 1/2 miles"},
    {value: "3", display: "Within 3 miles"},
    {value: "3.5", display: "Within 3 1/2 miles"},
    {value: "4", display: "Within 4 miles"},
    {value: "4.5", display: "Within 4 1/2 miles"},
    {value: "5", display: "Within 5 miles"},
    {value: "5.5", display: "Within 5 1/2 miles"},
    {value: "6", display: "Within 6 miles"},
    {value: "6.5", display: "Within 6 1/2 miles"},
]

export const SfAppStatusLetters = {
    'Incomplete letter': 'incomplete_letter',
    'Completion letter': 'complete_letter',
    'Approval letter': 'planning_approval_letter',
}

export const SfEnvTypes = {
    'Mit. neg. determination': 'mnd',
    'Appeal': 'appeal',
    'Mitigation monitoring': 'mmrp',
    'Technical report': 'technical_report',
    'Historic resource review': 'hrr',
    'Historic resource evaluation review': 'HRER',
}

export const SfRecordStatus = {
    'Open': 'open',
    'Closed/completed': 'closed',
    'Canceled/incomplete': 'canceled_incomplete',
    'Withdrawn': 'withdrawn',
}